import { ProfileUserDTO } from 'types/profileUser.interface';
import Roles from 'types/roles.enum';
import { User } from 'types/user.entity';
import { HomeData } from 'types/user.home.interface';

export interface IUserState {
  role: Roles;
  ui: IUserUI;
  onboarding: onboardingData;
  newOnboarding: onboardingSteps;
  userProfileModal: boolean;
  affiliateModal: boolean;
  userProfileData: ProfileUserDTO | undefined;
  promoCodeData?: PromoCodeData;
}

export interface UserProfileInfo {
  firstName: string;
  lastName: string;
  email: string;
  age: string;
  country: string;
}

export interface PromoCodeData {
  message: string;
  updatedCredits?: number;
}

export enum AudioProvider {
  ELEVENLABS = 'ELEVENLABS',
  OPENAI = 'OPENAI'
}

interface IUserUI {
  profilePostError?: string;
  signupError?: string;
  inviteError?: string;
  loginError?: string;
  loading: boolean;
  homeData?: HomeData;
  userInfo?: Partial<User>;
  userInfoError?: string;
  homeDataError?: string;
  updateError?: string;
  restorePasswordError?: string;
  waitingListError?: string;
  requestError?: string;
}

interface onboardingData {
  audioPreference?: string;
  shortLongTermGoals: string;
  motivations: string;
  aims: string;
}

interface onboardingSteps {
  actualStep: number;
  lastStep: number;
}

export interface UserOnboardingData {
  shortLongTermGoals: string;
  motivations: string;
  aims: string;
}

interface onboardingSteps {
  actualStep: number;
  lastStep: number;
}

export interface PromotionApplication {
  message: string;
  updatedCredits?: number;
}

export interface PromotionData {
  code: string;
}
