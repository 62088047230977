import { AudioProvider } from '../redux/user/types/userState.interface';
import { SubscriptionPlan } from '../types/user.entity';

export const getAudioProvider = (
  credits: number = 0,
  plan: SubscriptionPlan = SubscriptionPlan.NONE
): AudioProvider => {
  if (plan === SubscriptionPlan.PRO) {
    if (credits > 20) return AudioProvider.ELEVENLABS;
    return AudioProvider.OPENAI;
  }

  if (plan === SubscriptionPlan.PREMIUM) {
    if (credits > 10) return AudioProvider.ELEVENLABS;
    return AudioProvider.OPENAI;
  }

  if (plan === SubscriptionPlan.BASIC) {
    return AudioProvider.OPENAI;
  }

  return AudioProvider.OPENAI;
};
