import { ReactComponent as Bolt } from 'assets/images/Bolt.svg';
import { ReactComponent as CloseTag } from 'assets/images/Close_Button.svg';
import { ReactComponent as UserIcon } from 'assets/images/User.svg';
import { ReactComponent as Logo } from 'assets/images/iAffirm-logo.svg';
import { ReactComponent as LogoText } from 'assets/images/iAffirm-text.svg';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import SettingsIcon from '@mui/icons-material/Settings';
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  MenuItem,
  Select,
  Skeleton,
  Slide,
  Toolbar,
  Typography
} from '@mui/material';

import { InvitationModal } from 'components/InvitationModal';
import { PromotionModal } from 'components/PromotionModal';
import UpgradeYourPlanModal from 'components/UpgradeYourPlanModal';

import HamburgerMenu from 'design/HamburgerMenu';
import { Colors } from 'design/theme';

import { BootstrapInput } from 'modules/new-affirmation/components/CardSelect';
import { cleanWantWorkOn } from 'modules/new-affirmation/redux/ui/ui.slice';

import {
  getAffiliationStatus,
  getCredentialsStatus
} from 'redux/affiliation/affiliations.action';
import { affiliationSelector } from 'redux/affiliation/affiliations.selector';
import { getStripeProducts } from 'redux/stipeSubscription/stripe.action';
import { restoreReduxState } from 'redux/store';
import { getUserCredits } from 'redux/subscription/subscription.action';
import { subscriptionSelector } from 'redux/subscription/subscription.selector';
import { getUserInfo } from 'redux/user/user.action';
import { userSelector, userUiSelector } from 'redux/user/user.selector';
import { setAffiliateModal, setUserProfileModal } from 'redux/user/user.slice';

import Roles, { UserSubscription } from 'types/roles.enum';
import RoutesEnum from 'types/routes.enum';

import { getCurrencyByCountryName } from 'utils/getLocation';
import isLogged from 'utils/isLogged';
import { removeLocalStorage } from 'utils/localstorage';

const serviceEnv = process.env.REACT_APP_SERVICE ?? 'PRODUCTION';
const FullScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLargeScreen = useIsLargeScreen();

  const [isScrolled, setIsScrolled] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [openModalPromotion, setOpenModalPromotion] = useState<boolean>(false);
  const [confirmCredits, setConfirmCredits] = useState<boolean>(false);

  const [showOnboardingMessage, setShowOnboardingMessage] =
    useState<boolean>(false);

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  const togglePromotionModal = () => {
    setConfirmCredits(false);
    setOpenModalPromotion((prev) => !prev);
  };

  const closeOnboardingMessage = () => {
    setShowOnboardingMessage(false);
  };

  const userInfo = useAppSelector(userSelector).ui.userInfo;

  const affiliationStatus =
    useAppSelector(affiliationSelector).affiliationStatus;
  const { loading } = useAppSelector(userUiSelector);
  const userCredits = useAppSelector(subscriptionSelector);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 8) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowOnboardingMessage(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const Service = serviceEnv === 'STAGING';

  const isDisabled =
    userInfo?.subscription === UserSubscription.FREEMIUM ||
    (affiliationStatus.statusCreated && !affiliationStatus.statusReview);

  useEffect(() => {
    if (isLogged()) {
      dispatch(getUserInfo());
      dispatch(getUserCredits());
      dispatch(getAffiliationStatus());
      dispatch(
        getStripeProducts({
          currency: getCurrencyByCountryName(userInfo?.country || '')
        })
      );
    }
  }, [dispatch, userInfo?.country]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor:
            location.pathname === '/' && !isScrolled
              ? 'transparent'
              : Colors.white,
          transition: 'background 0.3s ease-in-out',
          boxShadow: 'none'
        }}>
        <Toolbar sx={{ padding: '.50rem 1rem' }}>
          <Container sx={{ padding: 0 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}>
              <Box
                display="flex"
                columnGap={isLargeScreen ? '1rem' : '.25rem'}
                alignItems="center"
                minWidth={'min-content'}>
                <Box
                  display="flex"
                  columnGap={isLargeScreen ? '1rem' : '.5rem'}
                  minWidth={'min-content'}
                  alignItems="center"
                  sx={{
                    cursor: 'pointer',
                    paddingRight: '1rem'
                  }}
                  onClick={() => {
                    if (location.pathname !== RoutesEnum.HOLDING) {
                      if (isLogged()) {
                        navigate(RoutesEnum.USER_HOME);
                      } else {
                        navigate(RoutesEnum.HOME);
                      }
                    }
                  }}>
                  <Logo />
                  <LogoText />
                </Box>
              </Box>

              <Box
                display="flex"
                columnGap={isLargeScreen ? '1rem' : '.25rem'}
                alignItems="center">
                {isLogged() ? (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '0.5rem',
                        gap: isLargeScreen ? '0.75rem' : '0.25rem',
                        cursor:
                          userInfo?.subscription !== UserSubscription.INVITEE
                            ? 'pointer'
                            : 'default'
                      }}
                      onClick={() => {
                        if (userInfo?.subscription !== Roles.INVITEE) {
                          handleOpen();
                        }
                      }}>
                      <Bolt />
                      {userCredits.isLoading ? (
                        <Skeleton variant="text" width="5rem" />
                      ) : userInfo?.subscription ===
                        UserSubscription.PREMIUM ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            gap:
                              userInfo?.credits && userInfo?.credits >= 1
                                ? '0.5rem'
                                : undefined
                          }}>
                          <Typography
                            data-testid="premiumUserLabel"
                            sx={{
                              color: Colors.primary.dark,
                              fontFamily: '"Nunito"',
                              fontSize: '1rem',
                              fontWeight: '500',
                              lineHeight: 'normal'
                            }}>
                            {userInfo?.plan?.split(' ')[0] || 'Premium'}
                          </Typography>{' '}
                          {userInfo?.credits && userInfo?.credits > 0 && (
                            <Typography
                              data-testid="availabeCredits"
                              sx={{
                                color: Colors.primary.main,
                                fontFamily: '"Nunito"',
                                fontSize: '0.875rem',
                                fontWeight: '500',
                                lineHeight: 'normal'
                              }}>
                              {' '}
                              Credits: {userInfo?.credits}
                            </Typography>
                          )}
                        </Box>
                      ) : userInfo?.subscription ===
                        UserSubscription.INVITEE ? (
                        <Typography
                          data-testid="premiumUserLabel"
                          sx={{
                            color: Colors.primary.dark,
                            fontFamily: '"Nunito"',
                            fontSize: '1rem',
                            fontWeight: '500',
                            lineHeight: 'normal'
                          }}>
                          Premium
                        </Typography>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            gap:
                              userInfo?.credits && userInfo?.credits >= 1
                                ? '0.5rem'
                                : undefined
                          }}>
                          <Typography
                            data-testid="freeUserLabel"
                            sx={{
                              color: Colors.primary.dark,
                              fontFamily: '"Nunito"',
                              fontSize: '1rem',
                              fontWeight: '500',
                              lineHeight: 'normal'
                            }}>
                            Free
                          </Typography>
                          {userInfo?.credits && userInfo?.credits > 0 && (
                            <Typography
                              data-testid="availabeCredits"
                              sx={{
                                color: Colors.primary.dark,
                                fontFamily: '"Nunito"',
                                fontSize: '1rem',
                                fontWeight: '500',
                                lineHeight: 'normal'
                              }}>
                              {' '}
                              Credits {userInfo?.credits}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Box>

                    {isLargeScreen ? (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          padding: '0.5rem',
                          gap: isLargeScreen ? '0.5rem' : '0.125rem'
                        }}>
                        <UserIcon />
                        {loading ? (
                          <Skeleton variant="text" width="3rem" />
                        ) : (
                          <Typography
                            data-testid="userName"
                            sx={{
                              color: Colors.primary.dark,
                              fontFamily: '"Nunito"',
                              fontSize: '1rem',
                              fontWeight: '500'
                            }}>
                            {userInfo?.firstName}
                          </Typography>
                        )}
                        <Select
                          input={<BootstrapInput />}
                          variant="standard"
                          value={''}
                          sx={{
                            background: 'transparent',
                            fontFamily: '"Nunito"'
                          }}
                          displayEmpty>
                          <MenuItem
                            onClick={() => {
                              navigate(RoutesEnum.USER_HOME);
                              dispatch(setUserProfileModal(true));
                              dispatch(cleanWantWorkOn());
                            }}
                            sx={{
                              borderBottom: `1px solid ${Colors.neutral}`,
                              minWidth: '13rem'
                            }}>
                            <Box>
                              <Typography
                                fontFamily='"Nunito"'
                                fontSize={'1rem'}
                                letterSpacing={'0.025rem'}>
                                {userInfo?.firstName || 'Profile'}
                              </Typography>
                              <Typography
                                fontFamily='"Nunito"'
                                fontSize={'0.75rem'}
                                color={Colors.neutral}
                                letterSpacing={'0.025rem'}>
                                {userInfo?.email}
                              </Typography>
                            </Box>
                            <SettingsIcon
                              sx={{
                                fontSize: 20,
                                ml: 2,
                                color: Colors.secondary.main
                              }}
                            />
                          </MenuItem>
                          {userInfo?.subscription !==
                            UserSubscription.INVITEE && (
                            <MenuItem onClick={toggleModal}>
                              Invite friends
                            </MenuItem>
                          )}
                          {userInfo?.subscription ===
                            UserSubscription.FREEMIUM && (
                            <MenuItem onClick={togglePromotionModal}>
                              Promotion code
                            </MenuItem>
                          )}
                          {!isDisabled && (
                            <MenuItem
                              onClick={() => {
                                navigate(RoutesEnum.USER_HOME);
                                dispatch(getAffiliationStatus());
                                dispatch(getCredentialsStatus());
                                dispatch(setAffiliateModal(true));
                                dispatch(cleanWantWorkOn());
                              }}>
                              Affiliates
                            </MenuItem>
                          )}
                          {isDisabled && (
                            <MenuItem
                              style={{ cursor: 'not-allowed', color: 'gray' }}>
                              Affiliates
                            </MenuItem>
                          )}
                          <MenuItem
                            data-testid="btnLogout"
                            onClick={() => {
                              dispatch(restoreReduxState());
                              removeLocalStorage('token');
                              navigate(RoutesEnum.HOME);
                            }}>
                            Logout
                          </MenuItem>
                        </Select>
                      </Box>
                    ) : (
                      <>
                        <HamburgerMenu>
                          <>
                            <MenuItem
                              onClick={() => {
                                navigate(RoutesEnum.USER_HOME);
                                dispatch(setUserProfileModal(true));
                                dispatch(cleanWantWorkOn());
                              }}>
                              <Box marginLeft="auto">
                                <Typography
                                  fontFamily='"Nunito"'
                                  fontSize={'1rem'}
                                  letterSpacing={'0.025rem'}
                                  textAlign="end">
                                  {userInfo?.firstName || 'Profile'}
                                </Typography>
                                <Typography
                                  fontFamily='"Nunito"'
                                  fontSize={'0.75rem'}
                                  color={Colors.neutral}
                                  letterSpacing={'0.025rem'}
                                  textAlign="end">
                                  {userInfo?.email}
                                </Typography>
                              </Box>
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={() => {
                                dispatch(restoreReduxState());
                                removeLocalStorage('token');
                                navigate(RoutesEnum.HOME);
                              }}>
                              <Typography
                                data-testid="btnLogout"
                                fontFamily='"Nunito"'
                                fontSize={'1rem'}
                                letterSpacing={'0.025rem'}
                                textAlign="end"
                                marginLeft="auto">
                                Logout
                              </Typography>
                            </MenuItem>
                            {Service && (
                              <MenuItem
                                onClick={() => {
                                  navigate(RoutesEnum.USER_HOME);
                                  dispatch(setAffiliateModal(true));
                                  dispatch(cleanWantWorkOn());
                                }}>
                                <Typography
                                  fontFamily='"Nunito"'
                                  fontSize={'1rem'}
                                  letterSpacing={'0.025rem'}
                                  textAlign="end"
                                  marginLeft="auto">
                                  Affiliates
                                </Typography>
                              </MenuItem>
                            )}
                          </>
                        </HamburgerMenu>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {location.pathname !== RoutesEnum.HOLDING && (
                      <>
                        <Button
                          color="secondary"
                          variant="outlined"
                          sx={{
                            padding: isLargeScreen
                              ? '0.6rem 1.5rem'
                              : '0.4rem 0.8rem',
                            borderRadius: '3.2px',
                            fontSize: isLargeScreen ? '0.8rem' : '0.6rem'
                          }}
                          onClick={() => navigate(RoutesEnum.LOGIN)}>
                          Login
                        </Button>

                        <Button
                          color="secondary"
                          variant="contained"
                          sx={{
                            padding: isLargeScreen
                              ? '0.6rem 1.5rem'
                              : '0.4rem 0.8rem',
                            borderRadius: '3.2px',
                            backgroundColor: Colors.bgGreen,
                            fontSize: isLargeScreen ? '0.8rem' : '0.6rem'
                          }}
                          onClick={() => navigate(RoutesEnum.SIGNUP)}>
                          Sign up
                        </Button>
                      </>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          pt: '4rem',
          minHeight: '100vh',
          background: 'whitesmoke',
          boxSizing: 'border-box'
        }}>
        {location.pathname !== RoutesEnum.HOLDING &&
          location.pathname !== RoutesEnum.ONBOARDING &&
          location.pathname !== RoutesEnum.HOME &&
          isLogged() &&
          showOnboardingMessage &&
          !userInfo?.onBoarding && (
            <Slide
              direction="down"
              in={showOnboardingMessage}
              mountOnEnter
              unmountOnExit>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: Colors.orange,
                  padding: '0.5rem 0',
                  position: 'relative'
                }}>
                <Button
                  onClick={() => navigate(RoutesEnum.ONBOARDING)}
                  sx={{
                    marginTop: '0.5rem',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'transparent'
                    }
                  }}>
                  <Typography
                    fontFamily={'"Nunito"'}
                    fontSize={'1rem'}
                    sx={{
                      color: Colors.secondary.dark,
                      textDecoration: 'underline'
                    }}>
                    {isLargeScreen
                      ? 'Complete your profile to get personalized affirmation'
                      : 'Complete your profile'}
                  </Typography>
                </Button>
                <Box
                  sx={{
                    position: 'absolute',
                    right: isLargeScreen ? 150 : 20,
                    top: '65%',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer'
                  }}
                  onClick={closeOnboardingMessage}>
                  <CloseTag />
                </Box>
              </Box>
            </Slide>
          )}

        <Outlet />
      </Box>
      <InvitationModal open={isModalOpen} handleClose={toggleModal} />
      <PromotionModal
        open={openModalPromotion}
        handleClose={togglePromotionModal}
        confirmCredits={confirmCredits}
        setConfirmCredits={setConfirmCredits}
      />
      <UpgradeYourPlanModal
        open={open}
        handleClose={() => {
          dispatch(getUserInfo());
          handleClose();
        }}
      />
    </>
  );
};

export default FullScreen;
