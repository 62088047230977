import { Socket, io } from 'socket.io-client';

import { getLocalStorage } from '../utils/localstorage';

let socket: Socket | null = null;

const createSocket = (): Socket => {
  const token = getLocalStorage('token');
  if (!token) {
    console.warn('Unable to authenticate. Socket connection may fail.');
  }
  return io(`${process.env.REACT_APP_SOCKET_GATEWAY}`, {
    auth: { token },
    withCredentials: true,
    reconnection: true,
    reconnectionAttempts: 15,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 9000
  });
};

export const getSocket = (): Socket => {
  if (!socket) {
    socket = createSocket();
  }
  return socket;
};
