import { IApiResponse } from 'types/apiResponse.interface';

import { Intros } from '../redux/intros/types/intros.interface';
import { api, baseURL } from './instance';

const _introsService = {
  get: () => {
    return api.get<IApiResponse<Intros[]>>(`${baseURL}/affirmations/intros`);
  }
};
export default _introsService;
