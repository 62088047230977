import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes/Routes';

import { ThemeProvider } from '@mui/material/styles';

import theme from 'design/theme';

import initializeAnalytics from './utils/analytics';

const TRACKING_ID = process.env.REACT_APP_ANALYTICS_ID;

const App = () => {
  useEffect(() => {
    initializeAnalytics(TRACKING_ID);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
