import {
  Action,
  combineReducers,
  configureStore,
  createAction
} from '@reduxjs/toolkit';

import newAffirmationSlice from 'modules/new-affirmation/redux/ui/ui.slice';

import affiliationsSlice from './affiliation/affiliations.slice';
import templateSlice from './affirmation-templates/templates.slice';
import affirmationSlice from './affirmation/afirmation.slice';
import audioProfilesSlice from './audio-profiles/audio-profiles.slice';
import chatSlice from './chat/chat.slice';
import introsSlice from './intros/intros.slice';
import meditationSlice from './meditations/meditation.slice';
import StripeSlice from './stipeSubscription/stripe.slice';
import subscriptionSlice from './subscription/subscription.slice';
import uploaderSlice from './uploader/uploader.slice';
import userSlice from './user/user.slice';
import visualizationSlice from './visualization/visualization.slice';

export const rootReducer = (state: any, action: Action<string>) => {
  if (action.type === 'RESET_STATE') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const appReducer = combineReducers({
  user: userSlice,
  uploader: uploaderSlice,
  newAffirmationUI: newAffirmationSlice,
  affirmation: affirmationSlice,
  visualization: visualizationSlice,
  subscription: subscriptionSlice,
  audioProfile: audioProfilesSlice,
  stripe: StripeSlice,
  template: templateSlice,
  affiliations: affiliationsSlice,
  medidation: meditationSlice,
  chat: chatSlice,
  intros: introsSlice
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true
});

export const restoreReduxState = createAction('RESET_STATE');

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
