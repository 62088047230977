import { createTheme } from '@mui/material/styles';

export const evo2FontFamily = "'Exo 2', sans-serif";

const brown = {
  light: '#6A4D3C',
  main: '#522808',
  dark: '#522808'
};
const green = {
  dark: '#293937',
  medium: '#2D5659',
  main: '#427E83',
  light: '#CFE0E2'
};

const primary = {
  dark: '#000000',
  main: '#427E83',
  light: '#FEF9F6'
};

const secondary = green;

export const Colors = {
  greenBlue: '#295871',
  white: '#FFFFFF',
  black: '#293937',
  successful: '#03BB50',
  primary,
  secondary,
  brown,
  green,
  neutral: '#B9B0AA',
  neutralGrey: '#76807E',
  neutralBlack: '#1F1F1F',
  grey: '#E9E7E5',
  lightGrey: '#DFDFDF',
  greyWhite: '#EFEFEF',
  shadowWhite: '#F5F5F5',
  orange: '#EF7619',
  bgGreen: '#356569',
  Geyser: '#7EB7C4',
  Bazaar: '#967C80',
  Cancel: '#D46A6A',
  HoldingGreen: '#2A393A',
  warningRed: '#E7DCDA',
  softWhite: '#FAFAFA',
  lightGreyGreen: '#576361',
  lightGreen: '#D7E8EA',
  salmonWhite: '#FEFAF7'
};

const theme = createTheme({
  palette: {
    mode: 'light',
    primary,
    secondary
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: Colors.white
        }
      }
    },
    MuiButton: { styleOverrides: { root: { textTransform: 'none' } } }
  },
  typography: {
    fontFamily: [
      '"Exo 2"',
      '"Nunito"',
      '"Inter"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Poppins"',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
});

export default theme;
