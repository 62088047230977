import useIsLargeScreen from 'hooks/useIsLargeScreen';

import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography
} from '@mui/material';

import { Colors, evo2FontFamily } from 'design/theme';

type FAQ = {
  question: string;
  answer: string;
};

const faqs: FAQ[] = [
  {
    question: 'What is iAffirm and how does it work?',
    answer:
      'iAffirm is a cutting-edge platform designed to harness the power of affirmations and visualization to facilitate personal growth and transformation. You can choose your focus area, set goals, select qualities and traits, customize your content, and even add background music. The platform then generates personalized affirmations, visualizations, or guided meditations tailored to your aspirations.'
  },
  {
    question: 'How can iAffirm benefit me?',
    answer:
      'iAffirm empowers you to reprogram your subconscious mind, align with your goals, and foster positive change in various aspects of your life. By using personalized affirmations and visualizations, you can boost your self-confidence, overcome challenges, and achieve your desired outcomes.'
  },
  {
    question: 'Is iAffirm suitable for everyone?',
    answer:
      "Absolutely! iAffirm is designed to cater to individuals from all walks of life. Whether you're looking to improve your inner self, enhance your general well-being, or excel in your professional endeavors, our platform can be customized to meet your unique needs."
  },
  {
    question: 'Can I use iAffirm on multiple devices?',
    answer:
      'Yes, iAffirm is accessible on various devices, including smartphones, tablets, and desktop computers. You can seamlessly switch between devices and continue your transformative journey wherever you are.'
  },
  {
    question: 'Are the generated affirmations and content truly personalized?',
    answer:
      "Yes, iAffirm uses AI algorithms to create content based on your selected goals, qualities, and traits. While it's generated by AI, you have the opportunity to review and customize the content to ensure it aligns perfectly with your vision and preferences."
  },
  {
    question: 'Is my data safe and secure on iAffirm?',
    answer:
      'Your privacy and data security are our top priorities. iAffirm employs advanced encryption and security measures to protect your information. We do not share your personal data with third parties without your consent.'
  },
  {
    question: 'Can I cancel or modify my subscription?',
    answer:
      'Yes, iAffirm offers flexible subscription options. You can easily manage your subscription, including canceling or modifying it, through your account settings.'
  },
  {
    question: 'Is there a trial period for iAffirm?',
    answer:
      'Yes, we offer a free trial period for you to explore iAffirm and experience its transformative potential before committing to a subscription.'
  },
  {
    question: 'How often should I use iAffirm for optimal results?',
    answer:
      "The frequency of use depends on your goals and preferences. Some users benefit from daily sessions, while others find success with a few sessions per week. It's essential to integrate iAffirm into your routine in a way that works best for you."
  },
  {
    question: 'Can I share my success story with iAffirm?',
    answer:
      "Absolutely! We'd love to hear about your journey and how iAffirm has impacted your life positively. Please feel free to share your success story with us, and you may inspire others on their transformative path. If you have any additional questions or need further assistance, don't hesitate to reach out to our support team. We're here to support you on your journey to"
  }
];

const FrequentlyAskedQuestions = () => {
  const isLargeScreen = useIsLargeScreen();
  return (
    <>
      <Box
        pb="12rem"
        pt={isLargeScreen ? '13rem' : '8rem'}
        sx={{
          background: Colors.salmonWhite
        }}>
        <Container>
          <Typography
            color={Colors.bgGreen}
            textAlign="center"
            fontFamily={evo2FontFamily}
            fontWeight={700}
            fontSize={isLargeScreen ? '2.625rem' : '2rem'}
            pb="4rem">
            Frequently Asked Questions
          </Typography>
          {faqs.map((faq, i) => (
            <Accordion
              key={i}
              sx={{
                borderRadius: '0.25rem',
                marginBottom: '.5rem',
                position: 'static',
                boxShadow: 'none',
                padding: { xs: '0 .5rem 0 1rem', sm: '1.5rem 3rem' }
              }}>
              <AccordionSummary
                expandIcon={<ExpandMore color="secondary" />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography
                  color={Colors.secondary.dark}
                  fontFamily={evo2FontFamily}
                  fontSize={isLargeScreen ? '1.25rem' : '0.9rem'}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  color={Colors.secondary.dark}
                  fontSize={isLargeScreen ? '1rem' : '0.9rem'}
                  fontFamily={evo2FontFamily}
                  fontWeight={500}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Container>
      </Box>
    </>
  );
};
export default FrequentlyAskedQuestions;
