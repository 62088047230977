import affirmationsPrev from 'assets/images/hub/affirmations-prev.png';
import avatarPrev from 'assets/images/hub/avatar-prev.png';
import meditationsPrev from 'assets/images/hub/guided-meditation.png';
import visualizationsPrev from 'assets/images/hub/visualization-prev.png';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Grid,
  Typography,
  alpha,
  useMediaQuery
} from '@mui/material';

import theme, { Colors } from 'design/theme';

import { userSelector } from 'redux/user/user.selector';

import { UserSubscription } from 'types/roles.enum';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { resetAffirmationState } from '../../../redux/affirmation/afirmation.slice';
import {
  cleanMeditation,
  setActualStepMeditation
} from '../../../redux/meditations/meditation.slice';
import { subscriptionSelector } from '../../../redux/subscription/subscription.selector';
import { getOutcomesVisualization } from '../../../redux/visualization/visualization.action';
import { cleanVisualization } from '../../../redux/visualization/visualization.slice';
import RoutesEnum from '../../../types/routes.enum';
import { User } from '../../../types/user.entity';
import { newAffirmationUISelector } from '../../new-affirmation/redux/ui/ui.selector';
import {
  cleanCustomFlow,
  initialStateNewAffirmationUiState,
  setActualStep,
  setCustomFlow,
  setHomeStatus,
  setLastStep
} from '../../new-affirmation/redux/ui/ui.slice';
import { Product } from '../enums/product.enum';
import { ProductOption } from '../interfaces/product-option.interface';

const catalogProducts: ProductOption[] = [
  {
    product: Product.AVATAR,
    title: 'Get a suggestion',
    description: 'Set your intentions and let AI suggest the best tool',
    tip: 'Ideal for personal exploration',
    image: avatarPrev,
    time: 3,
    isReady: true
  },
  {
    product: Product.AFFIRMATIONS,
    title: 'Create an affirmation routine',
    description: 'Daily intentions to set your day',
    tip: 'Good for shifting mindset',
    image: affirmationsPrev,
    time: 3,
    isReady: true
  },
  {
    product: Product.VISUALIZATIONS,
    title: 'Create a visualization audio',
    description: 'Picture your desired outcome',
    tip: 'Great to live and feel your goal',
    image: visualizationsPrev,
    time: 3,
    isReady: true
  },
  {
    product: Product.MEDITATIONS,
    title: 'Create a guided meditation',
    description: 'Relax and align your energy',
    tip: 'Best for focused intention',
    image: meditationsPrev,
    time: 3,
    isReady: true
  }
];

export const CatalogHome: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  const userInfo = useAppSelector(userSelector).ui.userInfo;
  const isSmallScreen = useMediaQuery(theme.breakpoints.up(430));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userCredits = useAppSelector(subscriptionSelector);
  const { wantWorkOn } = useAppSelector(newAffirmationUISelector);

  const checkUserAccess = (
    user: Partial<User> | undefined,
    isProductReady: boolean
  ): boolean => {
    if (!user) return false;

    return isProductReady;
  };

  const handleClick = (selectedOption: Product) => {
    switch (selectedOption) {
      case Product.AFFIRMATIONS:
        dispatch(resetAffirmationState());
        dispatch(
          setLastStep(
            initialStateNewAffirmationUiState.lastStep + wantWorkOn.length
          )
        );
        dispatch(setCustomFlow(false));
        dispatch(setHomeStatus(false));
        dispatch(cleanCustomFlow());
        dispatch(setActualStep(1));
        navigate(RoutesEnum.NEW_AFFIRMATION);
        break;
      case Product.VISUALIZATIONS:
        dispatch(cleanVisualization());
        dispatch(getOutcomesVisualization());
        navigate(RoutesEnum.VISUALIZATION);
        break;
      case Product.MEDITATIONS:
        dispatch(cleanMeditation());
        dispatch(setActualStepMeditation(1));
        navigate(RoutesEnum.NEW_MEDITATION);
        break;
      case Product.AVATAR:
        navigate(RoutesEnum.AVATAR);
        break;
    }
  };

  return (
    <Grid container spacing={2.5} sx={{ padding: 0 }}>
      {catalogProducts.map((option) => (
        <Grid
          item
          key={option.product}
          sx={{ padding: 0 }}
          xs={6}
          sm={6}
          md={3}
          lg={3}>
          <Button
            data-testid="productOption"
            sx={{
              padding: 0,
              overflow: 'hidden',
              height: isLargeScreen ? '13.5rem' : '100%',
              width: '100%',
              maxWidth: { md: '17rem' },
              background: Colors.white,
              borderRadius: '0.5rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: Colors.green.main,
              '&:hover': {
                background: Colors.green.light,
                color: Colors.green.dark
              },
              '&:active': {
                boxShadow: 'inset 0px 2px 0px 0px rgba(255, 255, 255, 0)',
                background: Colors.green.light,
                color: Colors.green.dark
              },
              '&.Mui-disabled': {
                background: Colors.grey,
                color: Colors.grey,
                opacity: 0.5
              },
              '&.Mui-disabled:hover': {
                cursor: 'not-allowed'
              },
              boxShadow: `0px 2px 1px -1px ${alpha(Colors.primary.dark, 0.5)}`
            }}
            onClick={() => handleClick(option.product)}
            disabled={!checkUserAccess(userInfo, option.isReady)}>
            <Box
              sx={{
                width: '100%',
                height: '50%'
              }}>
              <Box
                component="img"
                src={option.image}
                alt={option.title}
                sx={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover'
                }}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: isSmallScreen ? 'flex-start' : 'center',
                alignItems: 'start',
                height: '50%',
                paddingY: '1rem',
                width: '100%',

                paddingX: '1rem',
                textAlign: 'left'
              }}>
              <Typography
                sx={{
                  fontFamily: 'Nunito',
                  fontSize: '1rem',
                  fontWeight: 500,
                  color: Colors.black
                }}>
                {option.title}
              </Typography>

              <Typography
                sx={{
                  fontFamily: 'Nunito',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  color: Colors.lightGreyGreen
                }}>
                {option.description}
              </Typography>
            </Box>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};
