import { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';

import { Box, Divider, Paper, Typography } from '@mui/material';

import { Colors } from '../../../design/theme';
import useIsLargeScreen from '../../../hooks/useIsLargeScreen';
import { chatSelector } from '../../../redux/chat/chat.selector';
import { MessageSender } from '../enums/MessageSender.enum';
import StatusAvatarPicture from './StatusAvatarPicture';
import ErrorAction from './ui/ErrorAction';

const TextChatBox = () => {
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const isLargeScreen = useIsLargeScreen();
  const { messages } = useSelector(chatSelector);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView();
  }, [messages]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '1rem',
        paddingTop: '2rem',
        paddingBottom: '1rem',
        paddingX: !isLargeScreen ? '1rem' : '0.5rem',
        overflowY: 'auto',
        direction: 'ltr',
        '&::-webkit-scrollbar': {
          scrollbarWidth: 'none',
          width: '8px',
          borderRadius: '6px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: Colors.grey,
          borderRadius: '6px',
          width: '2px'
        }
      }}>
      {!isLargeScreen && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '1rem'
          }}>
          <StatusAvatarPicture />
        </Box>
      )}
      {messages.map((message) => {
        const isFromUser = message.sender === MessageSender.USER;

        return (
          <Box
            key={message.id}
            sx={{
              display: 'flex',
              justifyContent: isFromUser ? 'flex-end' : 'flex-start',
              maxWidth: '100%',
              ...(!isLargeScreen && {
                marginLeft: isFromUser ? '3rem' : '0'
              }),
              ...(!isLargeScreen && {
                marginRight: isFromUser ? '0' : '3rem'
              })
            }}>
            <Paper
              elevation={1}
              sx={{
                maxWidth: '33.5rem',
                paddingX: '1rem',
                borderRadius: isFromUser
                  ? '1rem 1rem 0 1rem'
                  : '1rem 1rem 1rem 0',
                backgroundColor: isFromUser
                  ? Colors.green.main
                  : Colors.softWhite,
                color: isFromUser ? Colors.white : Colors.black,
                fontSize: '1rem',
                fontFamily: 'Nunito',
                fontWeight: 500,
                width: 'auto'
              }}>
              {isFromUser && (
                <Typography
                  sx={{
                    paddingY: '1rem',
                    fontSize: '1rem',
                    fontFamily: 'Nunito',
                    fontWeight: 500,
                    width: 'auto',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word'
                  }}>
                  {message.text}
                </Typography>
              )}

              {!isFromUser && message.text && (
                <ReactMarkdown>
                  {message.text + (message?.error ? '...' : '')}
                </ReactMarkdown>
              )}

              {message?.error && (
                <>
                  {message.text && <Divider />}
                  <ErrorAction
                    messageError={message.error}
                    messageId={message.id}
                  />
                </>
              )}
            </Paper>
          </Box>
        );
      })}

      <div ref={chatEndRef} />
    </Box>
  );
};

export default TextChatBox;
