import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';

import { CircularLogoLoader } from 'components/CircularLogoLoader';

import { Colors } from 'design/theme';

import { generatePDFMeditation } from 'redux/meditations/meditation.action';
import { medidationSelector } from 'redux/meditations/meditation.selector';

import RoutesEnum from 'types/routes.enum';

import { ReactComponent as CloseTag } from '../../../assets/images/CloseSquare.svg';
import { analyticsEvents, logAnalyticsEvent } from '../../../utils/analytics';

const EditMeditation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userMeditation = useAppSelector(medidationSelector)?.userMeditation;
  const { loading } = useAppSelector(medidationSelector);
  const { id } = useParams();

  const [originalMessage, setOriginalMessage] = useState<string>(
    userMeditation.editMeditation?.message || ''
  );
  const [newMeditationMessage, setNewMeditationMessage] =
    useState<string>(originalMessage);
  const [editable, setEditable] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    logAnalyticsEvent(analyticsEvents.meditation.editing_step);
  }, []);

  useEffect(() => {
    setIsChanged(newMeditationMessage !== originalMessage);
  }, [newMeditationMessage, originalMessage]);

  const handleEdit = () => {
    setEditable(true);
  };

  const saveEdit = async () => {
    if (id && userMeditation.editMeditation?.meditationTitle) {
      await dispatch(
        generatePDFMeditation({
          meditationTitle: userMeditation.editMeditation?.meditationTitle,
          messageMeditation: newMeditationMessage,
          meditationId: id
        })
      );
    }
    navigate(`${RoutesEnum.MEDITATION_VIEW}${id}`);
  };

  const saveIcon = () => {
    setEditable(false);

    setOriginalMessage(newMeditationMessage);
    setIsChanged(false);
  };

  return (
    <Box sx={{ paddingBottom: '3rem' }}>
      {loading ? (
        <Container
          sx={{
            maxWidth: '80%',
            height: '75vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <CircularLogoLoader />
            <Typography
              sx={{
                fontFamily: '"Nunito"',
                fontSize: '1.25rem',
                fontWeight: '500',
                marginBottom: '1.5rem',
                color: Colors.secondary.dark
              }}>
              Creating your Meditation
            </Typography>
          </Box>
        </Container>
      ) : (
        <Container
          sx={{
            maxWidth: '80%',
            marginTop: '3rem',
            justifyContent: 'center'
          }}>
          <Grid container>
            <Grid item sx={{ marginBottom: '0.5rem' }} xs={11.5}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: 28,
                  fontWeight: '500',
                  marginBottom: '1.5rem',
                  color: 'secondary.dark'
                }}>
                {'Edit Guided Meditation'}
              </Typography>
            </Grid>
            <Grid item xs={0.5}>
              <Button
                sx={{ padding: 0, minWidth: 0, backgroundColor: 'none' }}
                onClick={() => {
                  navigate(RoutesEnum.USER_HOME);
                }}>
                <Box sx={{ width: 24, height: 24 }}>
                  <CloseTag />
                </Box>
              </Button>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              backgroundColor: 'transparent',
              width: '100%',
              cursor: 'pointer'
            }}>
            <TextField
              value={newMeditationMessage}
              onChange={(e) => setNewMeditationMessage(e.target.value)}
              onClick={handleEdit}
              multiline
              sx={{
                flexGrow: 1,
                color: editable ? Colors.bgGreen : Colors.secondary.light, // Change text color based on editable state
                border: `1px solid ${Colors.secondary.light}`,
                backgroundColor: editable ? Colors.primary.light : 'transparent' // Optional background change for editable mode
              }}
            />
            <IconButton
              color="primary"
              onClick={saveIcon}
              disabled={!isChanged} // Disable the CheckIcon if no changes have been made
              sx={{
                marginLeft: '0.5rem',
                color: isChanged ? Colors.bgGreen : Colors.secondary.light // Change color when disabled
              }}>
              <CheckIcon />
            </IconButton>
          </Box>

          <Grid container justifyContent="flex-end" columnSpacing={2} py={4}>
            <Grid item>
              <Button
                onClick={saveEdit}
                type="submit"
                sx={{ backgroundColor: Colors.bgGreen }}
                variant="contained">
                Next
              </Button>
            </Grid>
          </Grid>
        </Container>
      )}
    </Box>
  );
};

export default EditMeditation;
