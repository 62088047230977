import { useNavigate } from 'react-router-dom';

import { Close } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

import { Colors } from '../../../design/theme';
import useIsLargeScreen from '../../../hooks/useIsLargeScreen';
import RoutesEnum from '../../../types/routes.enum';
import StatusAvatarPicture from './StatusAvatarPicture';

const AvatarAssistant = () => {
  const navigate = useNavigate();
  const isLargeScreen = useIsLargeScreen();

  if (!isLargeScreen) {
    return (
      <Box
        sx={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: `0 10px 10px  ${Colors.shadowWhite}`,
          zIndex: '200'
        }}>
        <Box
          sx={{
            width: '100%',
            height: '60px',
            background: Colors.white,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingX: '1.115rem'
          }}>
          <Typography
            sx={{
              fontSize: '1.25rem',
              fontFamily: '"Nunito"',
              color: Colors.neutralBlack,
              fontWeight: 500
            }}>
            Explore on your own
          </Typography>

          <Button
            sx={{
              padding: 0,
              minWidth: 0,
              backgroundColor: 'none',
              width: 40,
              height: 40,
              borderRadius: '5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            onClick={() => {
              navigate(RoutesEnum.USER_HOME);
            }}>
            <Close sx={{ color: '#141B34', width: 24, height: 24 }} />
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        width: '100%',
        paddingTop: '4.688rem',
        paddingBottom: '1.85rem',
        paddingX: '0.5rem'
      }}>
      <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <StatusAvatarPicture />

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              fontSize: '1.75rem',
              fontFamily: '"Nunito"',
              fontWeight: 500,
              color: Colors.neutralBlack
            }}>
            Explore on your own
          </Typography>

          <Typography
            sx={{
              fontSize: '1.25rem',
              fontFamily: '"Nunito"',
              color: Colors.neutralBlack,
              opacity: 0.5,
              fontWeight: 500,
              marginTop: '-0.15rem'
            }}>
            What’s on your mind? 👋
          </Typography>
        </Box>
      </Box>

      <Button
        sx={{
          padding: 0,
          minWidth: 0,
          backgroundColor: 'none',
          width: 40,
          height: 40,
          border: '1px solid',
          borderRadius: '5px',
          borderColor: Colors.grey,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        onClick={() => {
          navigate(RoutesEnum.USER_HOME);
        }}>
        <Close sx={{ color: '#141B34', width: 20, height: 20 }} />
      </Button>
    </Box>
  );
};

export default AvatarAssistant;
