import {
  AffirmationParameters,
  MeditationParameters,
  VisualizationParameters
} from '../types/suggested-action-payload.interface';

export const isVisualizationParameters = (
  obj: any
): obj is VisualizationParameters =>
  typeof obj === 'object' &&
  obj !== null &&
  typeof obj.title === 'string' &&
  typeof obj.outcome === 'string' &&
  typeof obj.quality === 'string' &&
  typeof obj.emotion === 'string' &&
  typeof obj.situation === 'string';

export const isMeditationParameters = (obj: any): obj is MeditationParameters =>
  typeof obj === 'object' &&
  obj !== null &&
  typeof obj.title === 'string' &&
  typeof obj.outcome === 'string' &&
  typeof obj.quality === 'string' &&
  typeof obj.goal === 'string' &&
  typeof obj.attribute === 'string';

export const isAffirmationParameters = (
  obj: any
): obj is AffirmationParameters =>
  typeof obj === 'object' &&
  obj !== null &&
  typeof obj.title === 'string' &&
  typeof obj.outcome === 'string' &&
  Array.isArray(obj.qualities) &&
  obj.qualities.every((quality: unknown) => typeof quality === 'string');
