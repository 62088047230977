import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, Grid, Typography } from '@mui/material';

import { fetchAffirmationsInTemplate } from 'redux/affirmation-templates/templates.action';
import {
  generateAffirmation,
  generateCustomAffirmation,
  postAffirmation
} from 'redux/affirmation/affirmation.action';
import { affirmationSelector } from 'redux/affirmation/affirmation.selector';
import { audioProfilesSelector } from 'redux/audio-profiles/audio-profiles.selector';
import { getUserInfo } from 'redux/user/user.action';

import RoutesEnum from 'types/routes.enum';

import { transformCustomAffirmation } from 'utils/CustomAffirmationTransform';

import { ReactComponent as CloseTag } from '../../../assets/images/CloseSquare.svg';
import CustomOutcome from '../components/CustomSteps/1_CustomOutcome';
import CustomQualities from '../components/CustomSteps/2_CustomQualities';
import CustomTraitFirstOption from '../components/CustomSteps/3_CustomTraitFirstOption';
import CustomTraitSecondOption from '../components/CustomSteps/3_CustomTraitSecondOption';
import CustomTraitThirdOption from '../components/CustomSteps/3_CustomTraitThirdOption';
import AudioDuration from '../components/Steps/1_AudioDuration';
import ImprovementArea from '../components/Steps/2_improvementArea';
import WantWork from '../components/Steps/3_WantWork';
import TraitsForFirstOption from '../components/Steps/4_TraitsForFirstOption';
import TraitsForSecondOption from '../components/Steps/5_TraitsForSecondOption';
import SelectedAffirmations from '../components/Steps/6_SelectedAffirmations';
import SelectedAffirmationsTemplate from '../components/Steps/6_SelectedAffirmationsTemplate';
import AffirmationReview from '../components/Steps/7_AffirmationsReview';
import AffirmationsReviewMobile from '../components/Steps/7_AffirmationsReviewMobile';
import TraitsForThreeOption from '../components/Steps/8_TraitsForThreeOption';
import StepsView from '../components/StepsView';
import { newAffirmationUISelector } from '../redux/ui/ui.selector';
import { setActualStep, setCustomFlow } from '../redux/ui/ui.slice';

const NewAffirmation: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLargeScreen = useIsLargeScreen();
  const {
    actualStep,
    lastStep,
    selectedTraits,
    customFlow,
    customAffirmation,
    areaSelected
  } = useAppSelector(newAffirmationUISelector);
  const { selectedOutcome, createdAffirmation, selectedTemplate } =
    useAppSelector(affirmationSelector);
  const [additionalQualities, setAdditionalQualities] = useState<string[]>([]);
  const [readySelect, setReadySelect] = useState<boolean>(false);
  const { audioDuration } = useAppSelector(audioProfilesSelector);
  const handlePreviousStep = () => dispatch(setActualStep(actualStep - 1));
  const isSelectedAffirmationStep = actualStep === lastStep - 1;
  const isSelectedCustomAffirmationStep = actualStep === lastStep;
  const isLastStep = actualStep === lastStep;
  const [readyToDispatch, setReadyToDispatch] = useState(false);

  const postAffirmationCalled = useRef(false);

  const handleNextStep = async () => {
    const canGetAffirmations = actualStep === lastStep - 2 && selectedOutcome;
    if (
      canGetAffirmations &&
      !selectedTemplate &&
      !customFlow &&
      !readyToDispatch
    ) {
      await dispatch(
        generateAffirmation({
          outcome: selectedOutcome,
          selectedTraits,
          additionalQualities
        })
      );
      await dispatch(getUserInfo());
    }

    if (actualStep === lastStep - 2 && customFlow) {
      if (areaSelected && customAffirmation) {
        setReadyToDispatch(true);
      }
    }

    dispatch(setActualStep(actualStep + 1));
  };

  useEffect(() => {
    if (readyToDispatch) {
      if (areaSelected && customAffirmation) {
        const customParameters = transformCustomAffirmation(
          areaSelected?.area,
          customAffirmation
        );
        dispatch(
          generateCustomAffirmation({
            customAffirmation: customFlow,
            customParameters: customParameters
          })
        );
        dispatch(getUserInfo());
      }
      setReadySelect(true);
      setReadyToDispatch(false);
    }
  }, [readyToDispatch, areaSelected, customAffirmation, customFlow, dispatch]);

  useEffect(() => {
    if (
      createdAffirmation &&
      createdAffirmation.affirmationId &&
      !selectedTemplate &&
      !customFlow &&
      !readySelect &&
      !isLastStep &&
      !postAffirmationCalled.current
    ) {
      dispatch(
        postAffirmation({
          affirmationId: createdAffirmation.affirmationId,
          duration: audioDuration,
          customAffirmation: false
        })
      );
      postAffirmationCalled.current = true;
    }

    if (!customFlow && selectedTemplate && actualStep === 3) {
      dispatch(fetchAffirmationsInTemplate(selectedTemplate.id));
    }

    if (
      createdAffirmation &&
      createdAffirmation.affirmationId &&
      !selectedTemplate &&
      customFlow &&
      !isLastStep &&
      !postAffirmationCalled.current
    ) {
      dispatch(
        postAffirmation({
          affirmationId: createdAffirmation.affirmationId,
          duration: audioDuration,
          customAffirmation: customFlow
        })
      );
      dispatch(setCustomFlow(false));
      postAffirmationCalled.current = true;
    }
  }, [
    createdAffirmation,
    audioDuration,
    dispatch,
    actualStep,
    selectedTemplate,
    customFlow,
    readySelect,
    isLastStep
  ]);

  if (!isSelectedAffirmationStep && isLastStep && !customFlow) {
    return (
      <Box>
        {isLargeScreen ? <AffirmationReview /> : <AffirmationsReviewMobile />}
      </Box>
    );
  }

  return (
    <Container
      sx={{ maxWidth: '80%', marginTop: '3rem', justifyContent: 'center' }}>
      <Grid container>
        <Grid item sx={{ marginBottom: '0.5rem' }} xs={11.5}>
          <Typography
            variant="h1"
            sx={{
              fontSize: 28,
              fontWeight: '500',
              marginBottom: '1.5rem',
              color: 'secondary.dark'
            }}>
            {selectedOutcome ? `${selectedOutcome.outcome}` : 'New Affirmation'}
          </Typography>
        </Grid>
        <Grid item xs={0.5}>
          <Button
            sx={{ padding: 0, minWidth: 0, backgroundColor: 'none' }}
            onClick={() => {
              navigate(RoutesEnum.USER_HOME);
              dispatch(setCustomFlow(false));
            }}>
            <Box sx={{ width: 24, height: 24 }}>
              <CloseTag />
            </Box>
          </Button>
        </Grid>
      </Grid>

      <StepsView />
      {actualStep === 1 && <AudioDuration />}
      {actualStep === 2 && <ImprovementArea />}
      {actualStep === 3 && selectedTemplate && <SelectedAffirmationsTemplate />}

      {customFlow && actualStep === 3 && !selectedTemplate && <CustomOutcome />}
      {!customFlow && actualStep === 3 && !selectedTemplate && (
        <WantWork onAdditionalQualitiesChange={setAdditionalQualities} />
      )}
      {customFlow && actualStep === 4 && !selectedTemplate && (
        <CustomQualities />
      )}

      {customFlow && !selectedTemplate && actualStep === 5 && !isLastStep && (
        <CustomTraitFirstOption
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      )}

      {!isSelectedCustomAffirmationStep &&
        !isLastStep &&
        !selectedTemplate &&
        (!readySelect && customFlow && actualStep === 6 ? (
          <CustomTraitSecondOption
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
        ) : !readySelect && customFlow && actualStep === 7 ? (
          <CustomTraitThirdOption
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
        ) : null)}

      {isSelectedCustomAffirmationStep && !isLastStep && readySelect && (
        <SelectedAffirmations />
      )}

      {!customFlow && !selectedTemplate && actualStep === 4 && (
        <TraitsForFirstOption
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      )}

      {!isSelectedAffirmationStep &&
        !isLastStep &&
        (!customFlow && actualStep === 5 ? (
          <TraitsForSecondOption
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
        ) : !customFlow && actualStep === 6 ? (
          <TraitsForThreeOption
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
        ) : null)}
      {isSelectedAffirmationStep && !isLastStep && !customFlow && (
        <SelectedAffirmations />
      )}
    </Container>
  );
};

export default NewAffirmation;
