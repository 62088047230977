import { ReactComponent as CloseTag } from 'assets/images/Close_Button.svg';
import { Field, Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { PromotionData } from 'redux/user/types/userState.interface';
import { applyPromotionCode, getUserInfo } from 'redux/user/user.action';
import { userSelector } from 'redux/user/user.selector';

import { ReactComponent as Logo } from '../assets/images/iAffirm-logo.svg';

interface PromotionModalProps {
  open: boolean;
  handleClose: () => void;
  confirmCredits: boolean;
  setConfirmCredits: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormValues {
  promotionCode: string;
}

const initialValues: FormValues = {
  promotionCode: ''
};

export const PromotionModal: React.FC<PromotionModalProps> = ({
  open,
  handleClose,
  confirmCredits,
  setConfirmCredits
}) => {
  const dispatch = useAppDispatch();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const credits = useAppSelector(userSelector).promoCodeData?.updatedCredits;
  const errorMessage = useAppSelector(userSelector).promoCodeData?.message;
  const error = useAppSelector(userSelector).ui.requestError;

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '26rem',
    minWidth: '17rem',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  };

  const promotionSchema = Yup.object().shape({
    promotionCode: Yup.string().min(8)
  });

  const handlePromotionSubmit = async (values: FormValues) => {
    const data: PromotionData = {
      code: values.promotionCode
    };
    await dispatch(applyPromotionCode(data));
    setConfirmCredits(true);
    dispatch(getUserInfo());
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          {!confirmCredits && (
            <Formik
              initialValues={initialValues}
              validationSchema={promotionSchema}
              onSubmit={handlePromotionSubmit}
              validate={(values) => {
                try {
                  promotionSchema.validateSync(values, { abortEarly: false });
                  setIsFormValid(true);
                } catch (error) {
                  setIsFormValid(false);
                }
              }}>
              <Form>
                <Grid container alignItems="center" spacing={0} rowGap={2}>
                  <Grid item xs={11} sx={{ marginBottom: '0.5rem' }}>
                    <Typography
                      fontSize={'1.15rem'}
                      color={Colors.black}
                      lineHeight={'1.5rem'}>
                      Promotion Code
                    </Typography>
                  </Grid>
                  <Grid item xs={1} sx={{ marginBottom: '0.5rem' }}>
                    <Button
                      onClick={handleClose}
                      sx={{
                        padding: 0,
                        minWidth: 'auto'
                      }}>
                      <CloseTag />
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      fontSize={'1rem'}
                      color={Colors.black}
                      lineHeight={'1.5rem'}>
                      Add a promotion code to get all the benefits
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      label="Promotion code"
                      name="promotionCode"
                      variant="outlined"
                      fullWidth
                      sx={{
                        fontFamily: '"Nunito"',
                        fontSize: '1rem',
                        lineHeight: '1.5rem'
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    gap={1}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '1rem'
                    }}>
                    <Button
                      type="button"
                      variant="outlined"
                      onClick={handleClose}
                      color="secondary"
                      sx={{ border: 'none' }}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={!isFormValid}>
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          )}
          {confirmCredits && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem'
              }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={handleClose}
                  sx={{
                    padding: 0,
                    minWidth: 'auto'
                  }}>
                  <CloseTag />
                </Button>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Logo />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                {credits && !error && (
                  <>
                    <Typography>Welcome to iAffirm!</Typography>
                    <Typography>You now have {credits} credits</Typography>
                  </>
                )}
                {errorMessage && !error && (
                  <Typography>{errorMessage}</Typography>
                )}
                {error && (
                  <Typography>The promotion code is no longer valid</Typography>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};
