import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import Roles from 'types/roles.enum';
import { User } from 'types/user.entity';
import { HomeData } from 'types/user.home.interface';

import { IUserState } from './types/userState.interface';
import {
  applyPromotionCode,
  getProfile,
  getUserInfo,
  inviteUser,
  postProfileUser,
  restorePassword,
  submitOnboardingData,
  updateUser,
  userHome,
  userLogin,
  userSignUp,
  waitingList
} from './user.action';

const initialState: IUserState = {
  role: Roles.UNKNOW,
  ui: { loading: false },
  onboarding: {
    audioPreference: '',
    shortLongTermGoals: '',
    motivations: '',
    aims: ''
  },
  newOnboarding: { actualStep: 1, lastStep: 6 },
  userProfileModal: false,
  userProfileData: undefined,
  affiliateModal: false
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserRole: (state, action: PayloadAction<Roles>) => {
      state.role = action.payload;
    },
    setLoginError: (state, action: PayloadAction<string | undefined>) => {
      state.ui.loginError = action.payload;
    },
    clearWaitingListError: (state) => {
      state.ui.waitingListError = undefined;
    },
    setActualStep: (state, action: PayloadAction<number>) => {
      if (
        action.payload <= state.newOnboarding.lastStep &&
        action.payload >= 1
      ) {
        state.newOnboarding.actualStep = action.payload;
      }
    },
    setAudioProfile: (state, action: PayloadAction<string>) => {
      state.onboarding.audioPreference = action.payload;
    },
    setShortTermGoals: (state, action: PayloadAction<string>) => {
      state.onboarding.shortLongTermGoals = action.payload;
    },
    setMotivations: (state, action: PayloadAction<string>) => {
      state.onboarding.motivations = action.payload;
    },
    setAims: (state, action: PayloadAction<string>) => {
      state.onboarding.aims = action.payload;
    },
    setUserProfileModal: (state, action: PayloadAction<boolean>) => {
      state.userProfileModal = action.payload;
    },
    setUserProfileModalData: (state, action: PayloadAction<any>) => {
      state.userProfileData = action.payload;
    },
    resetUserHome: (state) => {
      state.ui.homeData = undefined;
    },
    setAffiliateModal: (state, action: PayloadAction<boolean>) => {
      state.affiliateModal = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.fulfilled, (state) => {
        state.ui.loading = false;
      })
      .addCase(userLogin.pending, (state) => {
        state.ui.loginError = undefined;
        state.ui.loading = true;
      })
      .addCase(userLogin.rejected, (state) => {
        state.ui.loginError = 'Wrong password';
        state.ui.loading = false;
      })
      .addCase(userSignUp.fulfilled, (state) => {
        state.ui.loading = false;
      })
      .addCase(userSignUp.pending, (state) => {
        state.ui.signupError = undefined;
        state.ui.loading = true;
      })
      .addCase(userSignUp.rejected, (state) => {
        state.ui.signupError = 'Something went wrong';
        state.ui.loading = false;
      })
      .addCase(inviteUser.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(inviteUser.fulfilled, (state) => {
        state.ui.loading = false;
      })
      .addCase(inviteUser.rejected, (state) => {
        state.ui.inviteError = 'Something went wrong when inviting';
        state.ui.loading = false;
      })
      .addCase(userHome.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(userHome.fulfilled, (state, action: PayloadAction<HomeData>) => {
        state.ui.homeData = action.payload;
        state.ui.loading = false;
      })
      .addCase(userHome.rejected, (state, action) => {
        state.ui.homeDataError =
          action.error.message || 'Error fetching home data';
        state.ui.loading = false;
      })
      .addCase(getUserInfo.pending, (state) => {
        state.ui.loading = true;
        state.ui.userInfoError = undefined;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.ui.userInfo = action.payload;
        state.ui.loading = false;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.ui.userInfoError =
          action.error.message || 'Error fetching user info';
        state.ui.loading = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.ui.loading = true;
        state.ui.updateError = undefined;
      })
      .addCase(updateUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.ui.userInfo = action.payload;
        state.ui.loading = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.ui.updateError =
          (action.payload as string) || 'Error updating user info';
        state.ui.loading = false;
      })
      .addCase(restorePassword.fulfilled, (state) => {
        state.ui.loading = false;
      })
      .addCase(restorePassword.pending, (state) => {
        state.ui.loading = true;
        state.ui.restorePasswordError = undefined;
      })
      .addCase(restorePassword.rejected, (state) => {
        state.ui.loading = false;
        state.ui.restorePasswordError = 'Incorrect password';
      })
      .addCase(waitingList.pending, (state) => {
        state.ui.loading = true;
        state.ui.waitingListError = undefined;
      })
      .addCase(waitingList.fulfilled, (state, action) => {
        state.ui.loading = false;
      })
      .addCase(waitingList.rejected, (state, action) => {
        state.ui.loading = false;
        state.ui.waitingListError =
          (action.payload as string) || 'Failed to add to the waiting list';
      })
      .addCase(submitOnboardingData.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(submitOnboardingData.fulfilled, (state) => {
        state.ui.loading = false;
      })
      .addCase(submitOnboardingData.rejected, (state) => {
        state.ui.loading = false;
      })
      .addCase(getProfile.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(getProfile.fulfilled, (state, action: PayloadAction<any>) => {
        state.userProfileData = action.payload; // Set both properties if needed
        state.ui.loading = false;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.ui.loading = false;
      })
      .addCase(postProfileUser.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(
        postProfileUser.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.ui.loading = false;
        }
      )
      .addCase(postProfileUser.rejected, (state, action) => {
        state.ui.profilePostError =
          action.error.message || 'Error posting profile';
        state.ui.loading = false;
      })
      .addCase(applyPromotionCode.fulfilled, (state, action) => {
        state.promoCodeData = action.payload;
      })
      .addCase(applyPromotionCode.pending, (state) => {
        state.ui.loading = true;
        state.ui.requestError = undefined;
      })
      .addCase(applyPromotionCode.rejected, (state, action) => {
        state.ui.loading = false;
        state.ui.requestError = action.error.message;
      });
  }
});

export const {
  setUserRole,
  setLoginError,
  clearWaitingListError,
  setActualStep,
  setAudioProfile,
  setShortTermGoals,
  setAims,
  setMotivations,
  setUserProfileModal,
  resetUserHome,
  setAffiliateModal
} = userSlice.actions;
export default userSlice.reducer;
