import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import { useState } from 'react';

import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { userSelector } from 'redux/user/user.selector';

import { UserSubscription } from 'types/roles.enum';
import { TabsProfile } from 'types/tabs.enum';

import PaymentForm from './PaymentForm';
import PlansChoose from './PlansChoose';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '38.5rem',
  maxWidth: '92%',
  maxHeight: '90%',
  bgcolor: Colors.white,
  boxShadow: '0px 0px 0.75rem 0px rgba(0, 0, 0, 0.22)',
  p: '1.5rem',
  borderRadius: '0.25rem',
  '&:focus-visible': { outline: 'none' },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflowY: 'auto'
};

interface IUpgradeYourPlanModalProps {
  open: boolean;
  handleClose: () => void;
}
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY || '');
const UpgradeYourPlanModal = ({
  open,
  handleClose
}: IUpgradeYourPlanModalProps) => {
  const userInfo = useAppSelector(userSelector).ui.userInfo;
  const isLargeScreen = useIsLargeScreen();

  const [selectedOption, setSelectedOption] = useState<string>(
    'price_1P21vg03fQfQu0KrS4LcFBvw'
  );

  const [view, setView] = useState<string>(TabsProfile.CHOOSE);

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
  };
  const handleUpgradeClick = () => {
    setView(TabsProfile.PAYMENT);
  };

  const renderContent = () => {
    switch (view) {
      case TabsProfile.CHOOSE:
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: isLargeScreen ? 'flex-end' : 'center',
              gap: isLargeScreen ? '4rem' : '1.5rem'
            }}>
            <Box sx={{ height: '100%' }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  fontFamily={'"Nunito"'}
                  color={Colors.secondary.dark}
                  fontSize={isLargeScreen ? '1.25rem' : '1rem'}
                  fontWeight={500}
                  lineHeight="2rem"
                  letterSpacing="0.5px">
                  Upgrade your plan
                </Typography>
                <IconButton
                  onClick={() => {
                    setView(TabsProfile.CHOOSE);
                    handleClose();
                  }}
                  size="small">
                  <Close />
                </IconButton>
              </Box>
              <PlansChoose
                onOptionChange={handleOptionChange}
                selectedOption={selectedOption}
              />
            </Box>
            <Box
              display="flex"
              gap={2}
              justifyContent="flex-end"
              flexWrap="wrap">
              <Button
                color="secondary"
                onClick={() => {
                  setView(TabsProfile.CHOOSE);
                  handleClose();
                }}
                fullWidth={!isLargeScreen}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleUpgradeClick}
                disabled={!selectedOption}
                fullWidth={!isLargeScreen}>
                Upgrade plan
              </Button>
            </Box>
          </Box>
        );
      case 'paymentForm':
        return (
          <>
            <Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  fontFamily={'"Nunito"'}
                  color={Colors.secondary.dark}
                  fontSize={isLargeScreen ? '1.25rem' : '1rem'}
                  fontWeight={500}
                  lineHeight="2rem"
                  letterSpacing="0.5px">
                  Join and subscribe
                </Typography>
                <IconButton
                  onClick={() => {
                    setView(TabsProfile.CHOOSE);
                    handleClose();
                  }}
                  size="small">
                  <Close />
                </IconButton>
              </Box>
              <Box>
                <Elements stripe={stripePromise}>
                  <PaymentForm
                    selectedOption={selectedOption}
                    tabBack={setView}
                  />
                </Elements>
              </Box>
            </Box>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          {userInfo?.subscription === UserSubscription.PREMIUM ? (
            <Box>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <IconButton
                  onClick={() => {
                    setView(TabsProfile.CHOOSE);
                    handleClose();
                  }}
                  size="small">
                  <Close />
                </IconButton>
              </Box>
              <Box>
                <Box
                  sx={{
                    minHeight: '18rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center'
                  }}>
                  <Typography
                    fontFamily={'"Nunito"'}
                    fontSize={'1.5rem'}
                    fontWeight={600}
                    sx={{ color: Colors.secondary.main, marginBottom: '1rem' }}>
                    {"You're Already a Premium Member!"}
                  </Typography>
                  <Typography
                    fontFamily={'"Nunito"'}
                    fontSize={'1rem'}
                    fontWeight={400}
                    sx={{ color: Colors.secondary.dark }}>
                    Manage your subscription details from your user profile.
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            renderContent()
          )}
        </Box>
      </Modal>
    </>
  );
};

export default UpgradeYourPlanModal;
