import { countryNameToCode, europeanCountries } from 'types/euCountries';

export const getCurrencyByCountryName = (countryName: string): string => {
  const countryCode = countryNameToCode[countryName];
  if (!countryCode) {
    return 'GBP'; // Default to USD if country code is not found
  }

  if (europeanCountries.includes(countryCode)) {
    return 'GBP';
  } else if (countryCode === 'GB') {
    return 'GBP';
  } else {
    return 'GBP';
  }
};
