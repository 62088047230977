import { Holding } from 'pages/Holding/Holding';
import Home from 'pages/Home/Home';
import LoginPage from 'pages/Login';
import RestorePasswordRequest from 'pages/RequestRestorePassword';
import RestorePassword from 'pages/RestorePassword';
import SignupPage from 'pages/Signup';
import { Navigate, Route, Routes as RoutesFromRouter } from 'react-router-dom';

import FullScreen from 'design/Layouts/FullScreen';
import HalfFullScreenCentered from 'design/Layouts/HalfFullScreenCentered';

import MeditationView from 'modules/new-guided-meditation/Meditation';
import EditMeditation from 'modules/new-guided-meditation/components/6_EditMeditation';
import { NewMeditation } from 'modules/new-guided-meditation/page/NewMeditation';
import VisualizationView from 'modules/new-visualization/components/Visualization';
import EditVisualization from 'modules/new-visualization/components/steps/Steps/EditVisualization';
import { NewVisualizations } from 'modules/new-visualization/page/NewVisualizations';
import OnboardingHandle from 'modules/onboarding/pages/OnboardingHandle';
import UserHub from 'modules/user/pages/UserHub';

import RoutesEnum from 'types/routes.enum';

import TermsAndConditions from '../components/TermsAndConditions';
import NewAffirmation from '../modules/new-affirmation/pages/NewAffirmation';
import Avatar from '../pages/Avatar';
import CreditRoute from './CreditRoute';
import ProtectedRoute from './ProtectRoute';

const Routes = () => {
  return (
    <RoutesFromRouter>
      <Route
        path={RoutesEnum.TERMS_CONDITIONS}
        element={<TermsAndConditions />}
      />

      <Route element={<FullScreen />}>
        <Route path={RoutesEnum.HOME} element={<Home />} />
        <Route path={RoutesEnum.ONBOARDING} element={<OnboardingHandle />} />
      </Route>
      <Route element={<HalfFullScreenCentered />}>
        <Route path={RoutesEnum.LOGIN} element={<LoginPage />} />
        <Route path={RoutesEnum.SIGNUP} element={<SignupPage />} />
        <Route
          path={RoutesEnum.RESTORE_REQUEST}
          element={<RestorePasswordRequest />}
        />
        <Route
          path={RoutesEnum.RESTORE_PASSWORD}
          element={<RestorePassword />}
        />
      </Route>

      <Route
        element={
          <ProtectedRoute>
            <FullScreen />
          </ProtectedRoute>
        }
        path={RoutesEnum.USER}>
        <Route path={RoutesEnum.USER_HOME} element={<UserHub />} />
        <Route path={RoutesEnum.AVATAR} element={<Avatar />} />
        <Route
          path={RoutesEnum.CHANGE_PASSWORD}
          element={<RestorePassword changePassword />}
        />

        <Route path={RoutesEnum.NEW_AFFIRMATION} element={<NewAffirmation />} />

        <Route
          path={RoutesEnum.VISUALIZATION}
          element={<NewVisualizations />}
        />

        <Route path={RoutesEnum.NEW_MEDITATION} element={<NewMeditation />} />

        <Route
          path={`${RoutesEnum.VISUALIZATION_VIEW}:id`}
          element={<VisualizationView />}
        />
        <Route
          path={`${RoutesEnum.EDIT_VISUALIZATION}:id`}
          element={<EditVisualization />}
        />
        <Route
          path={`${RoutesEnum.EDIT_MEDITATION}:id`}
          element={<EditMeditation />}
        />
        <Route
          path={`${RoutesEnum.MEDITATION_VIEW}:id`}
          element={<MeditationView />}
        />
        <Route path="*" element={<Navigate to={RoutesEnum.USER_HOME} />} />
      </Route>

      <Route path="*" element={<Navigate to={RoutesEnum.HOME} />} />
    </RoutesFromRouter>
  );
};

export default Routes;
