import { ReactComponent as CalendarNew } from 'assets/images/Calendar-new.svg';
import { ReactComponent as Target } from 'assets/images/Target.svg';
import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import {
  Forward10,
  PauseCircle,
  PlayCircle,
  Replay10
} from '@mui/icons-material';
import PendingIcon from '@mui/icons-material/Pending';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Skeleton,
  Typography,
  alpha
} from '@mui/material';

import { CircularLogoLoader } from 'components/CircularLogoLoader';

import { Colors } from 'design/theme';

import { deleteAffirmation } from 'redux/affirmation/affirmation.action';
import { deleteMeditation } from 'redux/meditations/meditation.action';
import { userHome } from 'redux/user/user.action';
import { deleteVisualization } from 'redux/visualization/visualization.action';

import millisToMinutesAndSeconds from 'utils/millisToMinutes';
import { truncateTitle } from 'utils/tittleTruncate';

import { ReactComponent as Download } from '../../assets/images/Download.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/Trash.svg';

interface ICustomAudioPlayerProps {
  id?: string;
  url?: string;
  title?: string;
  developmentArea?: string;
  createdOn: Date;
  isLoading?: boolean;
  variant?: 'default' | 'home';
  pdfUrl?: string;
  visualization: boolean;
  onTogglePlay?: () => void;
  isPlaying?: boolean;
  onGenerateLateAudio?: () => void;
  audioProcessed?: boolean;
  anyAudioProcessed?: boolean;
  area?: string;
}

const CustomAudioPlayer = ({
  id,
  url,
  title = 'Play your affirmation',
  createdOn,
  isLoading,
  variant,
  developmentArea,
  pdfUrl,
  onTogglePlay,
  isPlaying,
  visualization = false,
  onGenerateLateAudio,
  audioProcessed,
  anyAudioProcessed,
  area
}: ICustomAudioPlayerProps) => {
  const isLargesScreen = useIsLargeScreen();
  const [playing, setPlaying] = useState<boolean>(false);
  const [durationMs, setDurationMs] = useState<number>(0);
  const [progress, setProgress] = useState<{
    playedSeconds: number;
    played: number;
    loadedSeconds: number;
    loaded: number;
  }>({ loaded: 0, loadedSeconds: 0, played: 0, playedSeconds: 0 });
  const dispatch = useAppDispatch();
  const playerRef = useRef<ReactPlayer>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (typeof isPlaying !== 'undefined') {
      setPlaying(isPlaying);
    }
  }, [isPlaying]);
  const handlePlayPause = () => {
    if (onTogglePlay) {
      onTogglePlay();
    } else {
      setPlaying(!playing);
    }
  };
  const hasUrl = !!url;

  const handleRewind = () => {
    if (playerRef && playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(Math.max(currentTime - 10, 0), 'seconds');
    }
  };

  const handleForware = () => {
    if (playerRef && playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(Math.max(currentTime + 10, 0), 'seconds');
    }
  };

  const handleGenerateLateAudio = () => {
    if (onGenerateLateAudio) {
      onGenerateLateAudio();
    }
  };
  const handleCloseDeleteDialog = async () => {
    setDeleteDialogOpen(false);
  };

  const handleOpenDeleteDialog = async () => {
    setDeleteDialogOpen(true);
  };
  const handleDeleteAffirmation = async (area: string, id: string) => {
    if (area === 'Affirmation') {
      dispatch(deleteAffirmation(id));
      await dispatch(userHome(0));
      handleCloseDeleteDialog();
    }
    if (area === 'Visualization') {
      dispatch(deleteVisualization(id));
      await dispatch(userHome(0));
      handleCloseDeleteDialog();
    }
    if (area === 'Meditation') {
      dispatch(deleteMeditation(id));
      await dispatch(userHome(0));
      handleCloseDeleteDialog();
    }
  };
  if (variant === 'home')
    return (
      <Box
        sx={{
          borderRadius: '0.5rem',
          boxShadow: `0px 2px 1px -1px ${alpha(Colors.primary.dark, 0.5)}`,
          backgroundColor: Colors.white
        }}>
        <Box
          sx={{
            paddingY: '1.5rem',
            paddingX: '3rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1rem',
            width: '100%',
            margin: '0 auto',
            ...(!isLargesScreen && { flexWrap: 'wrap' })
          }}>
          <Box
            sx={{
              ...(!isLargesScreen && { width: '100%', textAlign: 'center' })
            }}>
            <Typography fontSize="1.25rem" fontWeight={500}>
              {truncateTitle(title, 65)}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: '.75rem',
                columnGap: '.5rem',
                width: '100%',
                justifyContent: isLargesScreen ? 'flex-start' : 'center'
              }}>
              <CalendarNew fontSize="small" sx={{ color: '#356569' }} />
              <Typography fontWeight={500} color="#576361" alignSelf="center">
                {dayjs(createdOn).format('MMM DD')}
              </Typography>
              {visualization ? (
                <Box ml="1.5rem" display="flex" gap=".5rem">
                  <Target sx={{ color: '#356569' }} />
                  <Typography
                    fontWeight={500}
                    color="#576361"
                    lineHeight="normal">
                    Visualization
                  </Typography>
                </Box>
              ) : (
                <Box display="flex" ml="1.5rem" gap=".5rem">
                  {!visualization && <Target />}
                  <Typography
                    fontWeight={500}
                    color="#576361"
                    alignSelf="center">
                    {developmentArea}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              minWidth: isLargesScreen ? undefined : '100%'
            }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              {url && developmentArea !== 'Morning' && (
                <Box
                  sx={{
                    border: `1px solid ${Colors.secondary.main}`,
                    borderRadius: '0.25rem',
                    marginRight: '1rem'
                  }}>
                  {!!pdfUrl && (
                    <Link href={pdfUrl}>
                      <IconButton color="secondary">
                        <Download />
                      </IconButton>
                    </Link>
                  )}
                </Box>
              )}
            </Box>

            <Box
              sx={{
                margin: { xs: '', md: 0 },
                minWidth: isLargesScreen ? undefined : '50%'
              }}>
              {url ? (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <IconButton
                      onClick={handleRewind}
                      disabled={isLoading}
                      color="secondary"
                      sx={{ height: 'fit-content' }}>
                      <Replay10 />
                    </IconButton>
                    <IconButton
                      onClick={handlePlayPause}
                      color="secondary"
                      size="large">
                      {isLoading ? (
                        <CircularLogoLoader />
                      ) : playing ? (
                        <PauseCircle fontSize="large" />
                      ) : (
                        <PlayCircle fontSize="large" />
                      )}
                    </IconButton>
                    <IconButton
                      onClick={handleForware}
                      color="secondary"
                      disabled={isLoading}
                      sx={{ height: 'fit-content' }}>
                      <Forward10 />
                    </IconButton>
                  </Box>
                </>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{}}>
                  {isLoading ? (
                    <>
                      <IconButton
                        onClick={handleRewind}
                        disabled={isLoading}
                        color="secondary"
                        sx={{ height: 'fit-content' }}>
                        <Replay10 />
                      </IconButton>
                      <IconButton color="secondary" size="large">
                        <CircularLogoLoader />
                      </IconButton>
                      <IconButton
                        onClick={handleForware}
                        color="secondary"
                        disabled={isLoading}
                        sx={{ height: 'fit-content' }}>
                        <Forward10 />
                      </IconButton>
                    </>
                  ) : audioProcessed ? (
                    <Box
                      sx={{
                        margin: 'auto',
                        background: Colors.green.light,
                        borderRadius: '0.5rem',
                        height: '3rem',
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                      <Box margin={'0.5rem'}>
                        <Typography
                          fontFamily='"Nunito"'
                          fontSize={'1rem'}
                          margin={'1.5rem'}
                          color={Colors.green.main}
                          sx={{ display: 'flex', alignItems: 'center' }}>
                          Creating your audio
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        gap: '1rem',
                        justifyContent: isLargesScreen
                          ? undefined
                          : 'space-between'
                      }}>
                      {id && (
                        <IconButton
                          onClick={() => {
                            handleOpenDeleteDialog();
                          }}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                      <Button
                        sx={{
                          border: `1px solid ${Colors.secondary.main}`,
                          borderRadius: '0.25rem',
                          color: Colors.secondary.main,
                          fontFamily: '"Nunito"',
                          fontSize: '1rem',
                          lineHeight: '1.25rem',
                          fontWeight: 500,
                          marginRight: isLargesScreen ? '0.5rem' : undefined,
                          paddingX: '1rem'
                        }}
                        disabled={anyAudioProcessed}
                        onClick={handleGenerateLateAudio}>
                        Create speech
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
              {isLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ maxWidth: '7rem', margin: 'auto' }}
                />
              ) : url ? (
                <>
                  <Typography
                    fontSize="0.875rem"
                    fontWeight={500}
                    color="#76807E"
                    textAlign="center">
                    {`${millisToMinutesAndSeconds(
                      durationMs * progress.played
                    )} / ${millisToMinutesAndSeconds(durationMs)}`}
                  </Typography>
                </>
              ) : (
                ''
              )}
              {!isLoading && (
                <ReactPlayer
                  ref={playerRef}
                  url={url}
                  playing={playing}
                  volume={1}
                  width="0px"
                  height="0px"
                  onDuration={(duration) => setDurationMs(duration)}
                  onProgress={(state) => setProgress(state)}
                />
              )}
            </Box>
            <Box>
              {url && id && developmentArea !== 'Morning' && (
                <Box
                  sx={{
                    border: `1px solid ${Colors.secondary.main}`,
                    borderRadius: '0.25rem',
                    marginLeft: '1rem'
                  }}>
                  <IconButton
                    onClick={() => {
                      handleOpenDeleteDialog();
                    }}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        {playing && (
          <Box
            sx={{
              position: 'relative',
              backgroundColor: Colors.secondary.light,
              width: '100%',
              height: '0.5rem',
              borderRadius: '0 0 0.5rem 0.5rem'
            }}>
            <Box
              sx={{
                position: 'abosolute',
                backgroundColor: Colors.secondary.main,
                width: `${100 * progress.played}%`,
                height: '0.5rem',
                borderRadius: '0 0 0 0.5rem'
              }}
            />
          </Box>
        )}
        {id && (
          <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
            <DialogTitle>Delete Affirmation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this affirmation? This action
                cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
              <Button
                onClick={() => handleDeleteAffirmation(area ? area : '', id)}
                color="error">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>
    );

  return (
    <Box borderRadius=".5rem" bgcolor={Colors.white}>
      <Box padding="1.5rem">
        <Typography fontSize="1.25rem" fontWeight={500} fontFamily='"Nunito"'>
          {title}
        </Typography>
        <Typography fontWeight={500} color="#576361" mt="0.5rem" mb="1.5rem">
          Created on {dayjs(createdOn).format('MMM DD, YYYY')}
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center">
          {isLoading ? (
            <CircularLogoLoader />
          ) : hasUrl ? (
            <>
              <IconButton
                onClick={handleRewind}
                disabled={isLoading}
                color="secondary"
                sx={{ height: 'fit-content' }}>
                <Replay10 />
              </IconButton>
              <IconButton
                onClick={handlePlayPause}
                color="secondary"
                size="large">
                {playing ? (
                  <PauseCircle fontSize="large" />
                ) : (
                  <PlayCircle fontSize="large" />
                )}
              </IconButton>
              <IconButton
                onClick={handleForware}
                color="secondary"
                disabled={isLoading}
                sx={{ height: 'fit-content' }}>
                <Forward10 />
              </IconButton>
            </>
          ) : (
            <PendingIcon style={{ fill: Colors.secondary.main }} />
          )}
        </Box>
        {isLoading ? (
          <Skeleton variant="text" sx={{ maxWidth: '7rem', margin: 'auto' }} />
        ) : (
          hasUrl && (
            <Typography
              fontSize="0.875rem"
              fontWeight={500}
              color="#76807E"
              textAlign="center">
              {`${millisToMinutesAndSeconds(
                durationMs * progress.played
              )} / ${millisToMinutesAndSeconds(durationMs)}`}
            </Typography>
          )
        )}
        {!isLoading && (
          <ReactPlayer
            ref={playerRef}
            url={url}
            playing={playing}
            volume={1}
            controls={true}
            width="0px"
            height="0px"
            onDuration={(duration) => setDurationMs(duration)}
            onProgress={(state) => setProgress(state)}
          />
        )}
      </Box>
      <Box
        sx={{
          position: 'relative',
          backgroundColor: Colors.secondary.light,
          width: '100%',
          height: '0.5rem',
          borderRadius: '0 0 0.5rem 0.5rem'
        }}>
        <Box
          sx={{
            position: 'abosolute',
            backgroundColor: Colors.secondary.main,
            width: `${100 * progress.played}%`,
            height: '0.5rem',
            borderRadius: '0 0 0 0.5rem'
          }}
        />
      </Box>
    </Box>
  );
};

export default CustomAudioPlayer;
