import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Socket } from 'socket.io-client';
import { DisconnectDescription } from 'socket.io-client/build/esm/socket';

import { Box, Container, Divider, Typography, alpha } from '@mui/material';

import { Colors } from '../design/theme';
import { useAppSelector } from '../hooks/useAppSelector';
import useIsLargeScreen from '../hooks/useIsLargeScreen';
import AvatarAssistant from '../modules/avatar/components/AvatarAssistant';
import ChatContainer from '../modules/avatar/components/ChatContainer';
import ChatInputWrapper from '../modules/avatar/components/ChatInputWrapper';
import SuggestedActions from '../modules/avatar/components/SuggestedActions';
import { ChatEvent } from '../modules/avatar/enums/ChatEvent.enum';
import { MessageError } from '../modules/avatar/enums/MessageError.enum';
import { MessageSender } from '../modules/avatar/enums/MessageSender.enum';
import useAudioPlayback from '../modules/avatar/hooks/useAudioPlayback';
import { ChatMessage } from '../modules/avatar/types/chat-message.type';
import { IncomingSuggestions } from '../modules/avatar/types/incoming-suggestions.interface';
import { SuggestedActionPayload } from '../modules/avatar/types/suggested-action-payload.interface';
import { chatSelector } from '../redux/chat/chat.selector';
import {
  addIncomingMessage,
  addMessage,
  removeConnectionErrorMessages,
  resetMessage,
  resetSuggestedAction,
  setIncomingSuggestedAction,
  setIsAnswering,
  setIsConnected,
  setIsError,
  setIsLoadingResponse,
  setMessageError,
  setMessages,
  setSuggestedAction
} from '../redux/chat/chat.slice';
import { userSelector } from '../redux/user/user.selector';
import { getSocket } from '../services/socket';

const Avatar = () => {
  const dispatch = useDispatch();
  const isLargeScreen = useIsLargeScreen();
  const originalUserInfo = useAppSelector(userSelector).ui.userInfo;
  const { messages, isConnected, isError, mode, isRecording } =
    useSelector(chatSelector);
  const [conversationId, setConversationId] = useState<string>();
  const disconnectTimerRef = useRef<NodeJS.Timeout | null>(null);
  const { enqueueAudio } = useAudioPlayback();
  const socket = getSocket();

  useEffect(() => {
    if (messages.length === 0 && originalUserInfo) {
      dispatch(
        setMessages([
          {
            id: 'initial-message',
            sender: MessageSender.AI,
            text: `Hi ${
              originalUserInfo?.firstName || 'there'
            }! To tailor your affirmations for the day, I’d like to ask: What would you like to work on? Take a moment to reflect on what feels most important right now.`
          }
        ])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages.length, originalUserInfo]);

  const onConnect = () => {
    if (disconnectTimerRef.current) {
      clearTimeout(disconnectTimerRef.current);
      disconnectTimerRef.current = null;
    }
    dispatch(setIsConnected(true));
    dispatch(setIsError(false));
    dispatch(removeConnectionErrorMessages());
  };

  const onDisconnect = (
    reason: Socket.DisconnectReason,
    description: DisconnectDescription | undefined
  ) => {
    if (disconnectTimerRef.current) clearTimeout(disconnectTimerRef.current);

    console.log('DISCONNECT --->', reason, description);
    disconnectTimerRef.current = setTimeout(() => {
      dispatch(setIsConnected(false));
      dispatch(
        addMessage({
          id: 'disconnect-message',
          sender: MessageSender.AI,
          error: MessageError.CONNECTION_ERROR,
          text: ''
        })
      );
    }, 9000);
  };

  const chatCleanUp = () => {
    Object.values(ChatEvent).forEach((event) => socket.off(event));
    socket.disconnect();
    dispatch(setMessages([]));
    dispatch(resetSuggestedAction());
    if (disconnectTimerRef.current) clearTimeout(disconnectTimerRef.current);
  };

  useEffect(() => {
    if (!isConnected && conversationId) {
      socket.io.opts.query = { conversationId: conversationId };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  useEffect(() => {
    if (!socket.connected || !isConnected) {
      socket.connect();
      dispatch(setIsConnected(true));
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', (reason, description) =>
      onDisconnect(reason, description)
    );

    socket.on(ChatEvent.START, (e: ChatMessage) => {
      setConversationId(e?.conversationId);
      dispatch(setIsLoadingResponse(true));
      dispatch(setIsAnswering(true));
      setIsError(false);
    });

    socket.on(ChatEvent.RESTART, (e: ChatMessage) => {
      dispatch(resetMessage(e));
    });

    socket.on(ChatEvent.MESSAGE, (e: ChatMessage, ack) => {
      dispatch(setIsLoadingResponse(false));
      if (e.text !== undefined && e.text !== null) {
        dispatch(addIncomingMessage(e));
      } else if (e?.text === undefined) {
        dispatch(setIsAnswering(false));
      }

      if (ack) ack(true);
    });

    socket.on(ChatEvent.USER_MESSAGE_CONFIRMED, (e: ChatMessage) => {
      dispatch(addMessage(e));
    });

    socket.on(
      ChatEvent.SUGGESTED_ACTION,
      (e: SuggestedActionPayload[], ack) => {
        dispatch(setSuggestedAction(e));
        if (ack) ack(true);
      }
    );

    socket.on(ChatEvent.SUGGESTED_ACTION_LOADING, (e: IncomingSuggestions) => {
      dispatch(setIncomingSuggestedAction(e));
    });

    socket.on(ChatEvent.MESSAGE_ERROR, (e: ChatMessage) => {
      dispatch(setIsLoadingResponse(false));
      dispatch(setMessageError(e));
    });

    socket.on(ChatEvent.ERROR, () => {
      dispatch(setIsLoadingResponse(false));
      dispatch(setIsError(true));
    });

    socket.on(ChatEvent.MESSAGE_AUDIO_CHUNK, async (chunk) => {
      dispatch(setIsLoadingResponse(false));
      await enqueueAudio(chunk);
    });

    return chatCleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container sx={{ justifyContent: 'center', paddingX: 0 }}>
      <Box
        sx={{
          height: originalUserInfo
            ? 'calc(100dvh - 4rem)'
            : 'calc(100dvh - 7.75rem)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
          maxWidth: '51.25rem',
          paddingBottom: '2.5rem'
        }}>
        <AvatarAssistant />

        {isLargeScreen && (
          <Divider
            sx={{
              width: '100%'
            }}
          />
        )}

        <ChatContainer />

        {isError && (
          <Typography sx={{ marginBottom: '1rem', color: Colors.Bazaar }}>
            Something went wrong, please refresh
          </Typography>
        )}

        <SuggestedActions />

        <Box
          sx={{
            position: 'relative',
            width: '100%',
            paddingTop: '0.25rem',
            zIndex: 10
          }}>
          {mode === 'text' && (
            <Box
              sx={{
                position: 'absolute',
                top: !isLargeScreen ? '-25px' : '-35px',
                left: 0,
                right: 0,
                height: '50%',
                background: `linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, ${Colors.shadowWhite} 90%)`,
                zIndex: 0
              }}
            />
          )}

          <ChatInputWrapper />
        </Box>

        {mode === 'voice' && isRecording && (
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '14.875rem',
              background: `linear-gradient(to top, ${alpha(
                Colors.primary.main,
                0.33
              )} 0%, rgba(0, 0, 0, 0) 100%)`,
              zIndex: 1
            }}
          />
        )}
      </Box>
    </Container>
  );
};

export default Avatar;
