import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';

const initializeAnalytics = (measurementId: string | undefined) => {
  if (!measurementId) {
    console.warn('Google Analytics Measurement ID is missing.');
    return;
  }

  ReactGA.initialize([
    {
      trackingId: measurementId
    }
  ]);
};

export const logAnalyticsEvent = (
  optionsOrName: UaEventOptions | string,
  params?: any
) => {
  ReactGA.event(optionsOrName, params);
};

export default initializeAnalytics;

export enum AnalyticsEvents {
  affirmation = 'affirmation',
  meditation = 'meditation',
  visualization = 'visualization',
  avatar = 'avatar'
}

export enum AffirmationActions {
  start = 'start',
  improvement = 'improvement_step',
  qualities = 'qualities_step',
  traits = 'traits_step',
  affirmationSelection = 'selection_step',
  affirmationView = 'view_affirmation_step',
  generate = 'generate_affirmation_audio'
}

export enum MeditationActions {
  start = 'start',
  outcome = 'outcome_step',
  quality = 'quality_step',
  goal = 'goal_step',
  attribute = 'attribute_step',
  edit = 'editing_step',
  meditationView = 'view_meditation_step',
  generate = 'generate_meditation_audio'
}

export enum VisualizationActions {
  start = 'start',
  improvement = 'improvement_step',
  situation = 'situation_step',
  feel = 'feel_step',
  feelWhile = 'feel_while_step',
  edit = 'editing_step',
  visualizationView = 'view_visualization_step',
  generate = 'generate_visualization_audio'
}

export enum AvatarActions {
  suggestedActionDisplay = 'suggested_action_display',
  selectSuggestedAction = 'select_suggested_action'
}

type AnalyticsEventMap = {
  [AnalyticsEvents.affirmation]: {
    [key in AffirmationActions]: UaEventOptions;
  };
  [AnalyticsEvents.meditation]: { [key in MeditationActions]: UaEventOptions };
  [AnalyticsEvents.visualization]: {
    [key in VisualizationActions]: UaEventOptions;
  };
  [AnalyticsEvents.avatar]: { [key in AvatarActions]: UaEventOptions };
};

export const analyticsEvents: AnalyticsEventMap = {
  [AnalyticsEvents.affirmation]: {
    [AffirmationActions.start]: {
      category: 'affirmation',
      action: AffirmationActions.start
    },
    [AffirmationActions.improvement]: {
      category: 'affirmation',
      action: AffirmationActions.improvement
    },
    [AffirmationActions.qualities]: {
      category: 'affirmation',
      action: AffirmationActions.qualities
    },
    [AffirmationActions.traits]: {
      category: 'affirmation',
      action: AffirmationActions.traits
    },
    [AffirmationActions.affirmationSelection]: {
      category: 'affirmation',
      action: AffirmationActions.affirmationSelection
    },
    [AffirmationActions.affirmationView]: {
      category: 'affirmation',
      action: AffirmationActions.affirmationView
    },
    [AffirmationActions.generate]: {
      category: 'affirmation',
      action: AffirmationActions.generate
    }
  },
  [AnalyticsEvents.meditation]: {
    [MeditationActions.start]: {
      category: 'meditation',
      action: MeditationActions.start
    },
    [MeditationActions.outcome]: {
      category: 'meditation',
      action: MeditationActions.outcome
    },
    [MeditationActions.quality]: {
      category: 'meditation',
      action: MeditationActions.quality
    },
    [MeditationActions.goal]: {
      category: 'meditation',
      action: MeditationActions.goal
    },
    [MeditationActions.attribute]: {
      category: 'meditation',
      action: MeditationActions.attribute
    },
    [MeditationActions.edit]: {
      category: 'meditation',
      action: MeditationActions.edit
    },
    [MeditationActions.meditationView]: {
      category: 'meditation',
      action: MeditationActions.meditationView
    },
    [MeditationActions.generate]: {
      category: 'meditation',
      action: MeditationActions.generate
    }
  },
  [AnalyticsEvents.visualization]: {
    [VisualizationActions.start]: {
      category: 'visualization',
      action: VisualizationActions.start
    },
    [VisualizationActions.improvement]: {
      category: 'visualization',
      action: VisualizationActions.improvement
    },
    [VisualizationActions.situation]: {
      category: 'visualization',
      action: VisualizationActions.situation
    },
    [VisualizationActions.feel]: {
      category: 'visualization',
      action: VisualizationActions.feel
    },
    [VisualizationActions.feelWhile]: {
      category: 'visualization',
      action: VisualizationActions.feelWhile
    },
    [VisualizationActions.edit]: {
      category: 'visualization',
      action: VisualizationActions.edit
    },
    [VisualizationActions.visualizationView]: {
      category: 'visualization',
      action: VisualizationActions.visualizationView
    },
    [VisualizationActions.generate]: {
      category: 'visualization',
      action: VisualizationActions.generate
    }
  },
  [AnalyticsEvents.avatar]: {
    [AvatarActions.suggestedActionDisplay]: {
      category: 'avatar',
      action: AvatarActions.suggestedActionDisplay
    },
    [AvatarActions.selectSuggestedAction]: {
      category: 'avatar',
      action: AvatarActions.selectSuggestedAction
    }
  }
};
