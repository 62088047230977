export const mapStripeProductsToPlanOptions = (stripeProducts: any[]) => {
  return stripeProducts.map((product) => {
    let recurrency: string;
    if (product.name.includes('Basic')) {
      recurrency = 'Basic Plan';
    } else if (product.name.includes('Premium')) {
      recurrency = 'Premium Plan';
    } else if (product.name.includes('Pro+')) {
      recurrency = 'Pro+ Plan';
    } else {
      recurrency = 'Unknown Plan';
    }

    const label =
      recurrency === 'Basic Plan'
        ? 'Affordable option'
        : recurrency === 'Premium Plan'
        ? 'Most popular'
        : recurrency === 'Pro+ Plan'
        ? 'All features included'
        : '';

    return {
      planId: product.id,
      recurrency,
      price: (product.price / 100).toFixed(2),
      currency: product.currency.toUpperCase(),
      label
    };
  });
};
