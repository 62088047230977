import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getIntros } from './intros.action';
import { Intros } from './types/intros.interface';
import { IntrosState } from './types/introsState.interface';

const initialState: IntrosState = {
  loading: false,
  intros: []
};

const introsSlice = createSlice({
  name: 'intros',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getIntros.fulfilled,
        (state, action: PayloadAction<Intros[]>) => {
          state.intros = action.payload;
          state.loading = false;
        }
      )
      .addCase(getIntros.pending, (state) => {
        state.loading = true;
      })
      .addCase(getIntros.rejected, (state) => {
        state.intros = [];
        state.loading = false;
      });
  }
});

export default introsSlice.reducer;
