import { ReactComponent as Stars } from 'assets/images/Stars.svg';
import useIsLargeScreen from 'hooks/useIsLargeScreen';

import { Box, Typography } from '@mui/material';

import { Colors } from 'design/theme';

const BoxOption = ({
  model,
  label,
  price,
  currency,
  isSelected
}: {
  model: string;
  label: string | undefined;
  price: string;
  currency: string;
  isSelected: boolean;
}) => {
  const isLargeScreen = useIsLargeScreen();

  return (
    <Box
      height={isLargeScreen ? '15.875rem' : 'auto'}
      width={isLargeScreen ? '11.25rem' : 'auto'}
      position="relative"
      sx={{ cursor: 'pointer' }}>
      <Box
        position="absolute"
        left="-3%"
        sx={{
          opacity: isSelected ? 1 : 0,
          transition: 'opacity 200ms ease-in-out'
        }}>
        <Stars />
      </Box>

      <Box
        height="100%"
        width="100%"
        borderRadius="0.25rem"
        border={`1px solid ${isSelected ? Colors.secondary.main : '#E9E7E5'}`}
        bgcolor={isSelected ? Colors.secondary.light : 'transparent'}
        p={isLargeScreen ? '1.5rem' : '1rem'}
        sx={{ transition: 'all 200ms ease-in-out' }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%">
          <Typography
            color={isSelected ? Colors.secondary.main : Colors.black}
            fontSize={'1.5rem'}
            fontWeight={500}
            mt={'1rem'}>
            {model}
          </Typography>
          {label ? (
            <Box sx={{ gap: '1rem' }}>
              <Typography
                color={isSelected ? Colors.secondary.main : '#76807E'}
                fontWeight={500}
                fontSize={isLargeScreen ? '1.25rem' : '1rem'}
                lineHeight="1.5rem"
                letterSpacing={'0.5px'}>
                {label}
              </Typography>
              <Typography
                color={isSelected ? Colors.secondary.main : Colors.neutralBlack}
                fontWeight={500}
                fontSize={isLargeScreen ? '1.25rem' : '1rem'}
                lineHeight="120%"
                letterSpacing={'0.5px'}>
                <br />
                {currency}
                {currency === 'GBP' ? '£ ' : '$ '}
                {price}
              </Typography>
            </Box>
          ) : (
            <Typography
              color={isSelected ? Colors.secondary.main : Colors.neutralBlack}
              fontWeight={500}
              fontSize={isLargeScreen ? '1.25rem' : '1rem'}
              lineHeight="120%">
              {currency}
              {currency === 'GBP' ? '£ ' : '$ '}
              {price}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BoxOption;
