import {
  Affirmation,
  Visualization
} from 'redux/affirmation/types/affirmation.entity';

import { AudioProvider } from '../redux/user/types/userState.interface';

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  age: string;
  country: string;
  verified: boolean;
  authType: AuthType;
  password?: string;
  subscription: string;
  credits?: number;
  subscriptionType: UserRole;
  affirmations: Affirmation[];
  visualizations: Visualization[];
  onBoarding: boolean;
  userProfile: any;
  stripeCustomerId: string;
  subscriptionStatus?: string;
  subscriptionEndDate?: string;
  audioProvider?: AudioProvider;
  plan: SubscriptionPlan;
};

export enum AuthType {
  EMAIL = 'email',
  APPLE = 'apple',
  GOOGLE = 'google',
  FACEBOOK = 'facebook'
}

export enum UserRole {
  UNREGISTERED = 'UNREGISTERED',
  FREE = 'FREE',
  SUBSCRIBED = 'SUBSCRIBED',
  INVITEE = 'INVITEE'
}

export enum SubscriptionPlan {
  NONE = '',
  PREMIUM = 'Premium plan',
  PRO = 'Pro+ plan',
  BASIC = 'Basic plan'
}
