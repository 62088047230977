import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Container, Grid } from '@mui/material';

import AffirmationHeader from 'components/AffirmationHeader';
import AffirmationHeaderMobile from 'components/AffirmationHeaderMobile';
import { CircularLogoLoader } from 'components/CircularLogoLoader';
import PDFViewerComponent from 'components/PDFrender/PDFViewer';
import PDFViewerMobile from 'components/PDFrender/PDFViewerMobile';

import { getAdminAudioProfiles } from 'redux/audio-profiles/audio-profiles.action';
import { getUserCredits } from 'redux/subscription/subscription.action';
import { generateVisualization } from 'redux/visualization/visualization.action';
import { visualizationSelector } from 'redux/visualization/visualization.selector';

import { userSelector } from '../../../redux/user/user.selector';
import { UserSubscription } from '../../../types/roles.enum';
import { getAudioProvider } from '../../../utils/getAudioProviderPlan';
import { AudioProfileVisualization } from './AudioProfile_Visualization';

const VisualizationView = () => {
  const dispatch = useAppDispatch();
  const isLargeScreen = useIsLargeScreen();
  const { id } = useParams();
  const userVisualizationData = useAppSelector(
    visualizationSelector
  )?.userVisualization;
  const userInfo = useAppSelector(userSelector).ui.userInfo;
  const userVisualization = userVisualizationData?.userVisualization;
  const title =
    userVisualization && userVisualization.contextTitle
      ? userVisualization?.contextTitle
      : 'Visualize the start of a successful day';

  const [hasFetchedVisualization, setHasFetchedVisualization] = useState(false);
  const [hasFetchedUserCredits, setHasFetchedUserCredits] = useState(false);

  useEffect(() => {
    if (id && !hasFetchedVisualization) {
      setHasFetchedVisualization(true);
      dispatch(generateVisualization({ visualizationId: id }));
    }
  }, [id, dispatch, hasFetchedVisualization]);

  useEffect(() => {
    if (userVisualization && !hasFetchedUserCredits) {
      setHasFetchedUserCredits(true);
      dispatch(getUserCredits());
    }
    if (userInfo?.subscription === UserSubscription.PREMIUM) {
      dispatch(
        getAdminAudioProfiles(getAudioProvider(userInfo.credits, userInfo.plan))
      );
    } else {
      dispatch(getAdminAudioProfiles(userInfo?.audioProvider));
    }
  }, [dispatch, userVisualization, hasFetchedUserCredits, userInfo]);

  if (!userVisualization)
    return (
      <Box
        sx={{
          height: 'calc(100vh - 5.5625rem)',
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <CircularLogoLoader />
      </Box>
    );

  return (
    <Box
      sx={{
        height: '100%',
        pb: '2rem'
      }}>
      {isLargeScreen && userVisualization ? (
        <AffirmationHeader
          title={title}
          createdOn={dayjs().toDate()}
          pdfUrl={userVisualization.visualizationURL}
          developmentArea={'Visualization'}
        />
      ) : (
        <AffirmationHeaderMobile
          title={title}
          createdOn={dayjs().toDate()}
          pdfUrl={userVisualization.visualizationURL}
          developmentArea={'Visualization'}
        />
      )}
      {userVisualization && (
        <Container>
          <Grid container flexWrap="wrap-reverse" mt="1.375rem" spacing={2}>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  borderRadius: '0.25rem'
                }}>
                {isLargeScreen ? (
                  userVisualization.visualizationURL && (
                    <PDFViewerComponent
                      pdfURL={userVisualization.visualizationURL}
                    />
                  )
                ) : (
                  <PDFViewerMobile
                    pdfURL={userVisualization.visualizationURL}
                  />
                )}
              </Box>
            </Grid>
            {isLargeScreen && (
              <Grid item xs={12} md={4}>
                <AudioProfileVisualization />
              </Grid>
            )}
          </Grid>
        </Container>
      )}
    </Box>
  );
};
export default VisualizationView;
