import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Alert, Box, Button, Grid, Snackbar } from '@mui/material';

import DefaultLibraryVisualization from 'components/DefaultLibraryVisualization';

import { Colors } from 'design/theme';

import { setIsSpeechProcessing } from 'redux/affirmation/afirmation.slice';
import { getUserCredits } from 'redux/subscription/subscription.action';
import { generateVisualization } from 'redux/visualization/visualization.action';
import { visualizationSelector } from 'redux/visualization/visualization.selector';

import RoutesEnum from 'types/routes.enum';

import { analyticsEvents, logAnalyticsEvent } from '../../../utils/analytics';

export const AudioProfileVisualization = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isSpeechProcessing } = useAppSelector(visualizationSelector);
  const [processingAudio, setProcessingAudio] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<'Library' | 'Custom'>('Library');

  const handleHomeClick = () => {
    setActiveTab('Library');
  };

  const { id } = useParams();
  const {
    userVisualization
    //  speech, speechLoading, isSpeechProcessing
  } = useAppSelector(visualizationSelector);

  useEffect(() => {
    logAnalyticsEvent(analyticsEvents.visualization.view_visualization_step);
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(generateVisualization({ visualizationId: id }));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (userVisualization) {
      dispatch(getUserCredits());
    }
  }, [dispatch, userVisualization]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setProcessingAudio(false);
    dispatch(setIsSpeechProcessing(false));
  };
  useEffect(() => {
    isSpeechProcessing ? setProcessingAudio(true) : setProcessingAudio(false);
  }, [isSpeechProcessing]);

  return (
    <Box sx={{ padding: '0 1.25rem' }}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={processingAudio}
        autoHideDuration={6000}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Your audio is currently being processed. Once ready, we will send you
          an email notification. Feel free to go to home if you wish.
        </Alert>
      </Snackbar>

      <Box
        sx={{
          width: '100%',
          backgroundColor: Colors.white,
          borderRadius: '0.25rem',
          marginBottom: '1.5rem'
        }}>
        <Grid
          container
          spacing={1}
          sx={{
            padding: '0',
            borderBottom: `1px solid ${Colors.grey}`,
            margin: '0',
            marginBottom: '1rem'
          }}>
          <Grid item>
            <Button
              sx={{
                fontFamily: '"Nunito"',
                fontSize: '1.15rem',
                borderBottom:
                  activeTab === 'Library'
                    ? `2px solid ${Colors.black}`
                    : undefined,
                borderRadius: '0',
                color: activeTab === 'Library' ? Colors.black : Colors.neutral,
                fontWeight: '500'
              }}
              onClick={handleHomeClick}>
              Library
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ padding: '2rem 2rem' }}>
          {activeTab === 'Library' && <DefaultLibraryVisualization />}
        </Box>
      </Box>
      {/* {userVisualization && (
        <CustomAudioPlayer
          title={'Play your visualization'}
          developmentArea={''}
          url={isSpeechProcessing ? undefined : speech}
          isLoading={speechLoading}
          createdOn={dayjs().toDate()}
          visualization={false}
        />
      )} */}
      <Grid
        item
        xs={12}
        sx={{
          margin: '1.5rem',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            navigate(RoutesEnum.USER_HOME);
          }}
          disabled={isSpeechProcessing ? false : true}
          sx={{ width: '60%' }}>
          Home
        </Button>
      </Grid>
    </Box>
  );
};
