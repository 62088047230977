import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Grid, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import {
  cleanWantWorkOn,
  setActualStep,
  setAreaSelected
} from 'modules/new-affirmation/redux/ui/ui.slice';

import { setSelectedOutcome } from 'redux/affirmation/afirmation.slice';
import { audioProfilesSelector } from 'redux/audio-profiles/audio-profiles.selector';
import { setAudioDuration } from 'redux/audio-profiles/audio-profiles.slice';
import { getAllMeditationOutcomes } from 'redux/meditations/meditation.action';
import { medidationSelector } from 'redux/meditations/meditation.selector';
import { setActualStepMeditation } from 'redux/meditations/meditation.slice';

import { AudioDurations } from 'types/durations.enum';
import RoutesEnum from 'types/routes.enum';

import { analyticsEvents, logAnalyticsEvent } from '../../../utils/analytics';

const durations = [
  {
    value: AudioDurations.EIGHT_MINUTES,
    label: '8',
    description: 'Quick catch-up',
    available: true
  },
  {
    value: AudioDurations.SIXTEEN_MINUTES,
    label: '16',
    description: 'Connect with your intentions and goals',
    available: false
  },
  {
    value: AudioDurations.TWENTYFOUR_MINUTES,
    label: '24',
    description: 'Get into a full affirmation session',
    available: false
  }
];

const handleScrollDown = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    behavior: 'smooth'
  });
};

export const AudioDuration: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { actualStep } = useAppSelector(medidationSelector)?.newMeditation;
  const { audioDuration } = useAppSelector(audioProfilesSelector);
  const [selectedDuration, setSelectedDuration] =
    useState<AudioDurations>(audioDuration);

  useEffect(() => {
    logAnalyticsEvent(analyticsEvents.meditation.start);
  }, []);

  const handleDurationSelect = (duration: AudioDurations) => {
    dispatch(setAudioDuration(duration));
    setSelectedDuration(duration);
  };

  const handleNextStep = async () => {
    await dispatch(getAllMeditationOutcomes());
    dispatch(setActualStepMeditation(actualStep + 1));
  };

  const buttonStyle = (duration: AudioDurations) => ({
    height: '7.625rem',
    width: '100%',
    background:
      selectedDuration === duration ? Colors.green.light : Colors.white,
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    color:
      selectedDuration === duration ? Colors.green.main : Colors.green.dark,
    border: `1px solid ${
      selectedDuration === duration ? Colors.green.main : Colors.lightGrey
    }`,
    '&:hover': {
      border: `2px solid ${Colors.green.main}`,
      background: Colors.green.light
    },
    '&:active': {
      boxShadow: 'inset 0px 2px 0px 0px rgba(53, 101, 105, 1)'
    }
  });

  useEffect(() => {
    dispatch(setSelectedOutcome(undefined));
    dispatch(setAreaSelected(undefined));
    dispatch(cleanWantWorkOn());
    dispatch(setActualStepMeditation(1));
  }, [dispatch]);
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        mb="1.5rem"
        alignItems="center">
        <Grid item>
          <Typography
            fontFamily='"Nunito"'
            fontSize={'1.5rem'}
            color={Colors.black}>
            Set the vibe
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            fontFamily='"Nunito"'
            fontSize={'0.875rem'}
            fontWeight={500}
            color={Colors.brown.light}>
            Select how much time you want to dedicate to this meditation
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        {durations.map(({ value, label, description, available }) => (
          <Grid item xs={12} key={value}>
            <Button
              disabled={!available}
              sx={buttonStyle(value)}
              onClick={() => {
                handleDurationSelect(value);
                handleScrollDown();
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%',
                    margin: isLargeScreen ? undefined : '1rem',
                    marginLeft: isLargeScreen ? '5rem' : undefined
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '0.25rem'
                    }}>
                    <Typography
                      fontFamily='"Nunito"'
                      fontSize={'1rem'}
                      fontWeight={600}
                      color={!available ? Colors.lightGrey : ''}>
                      {label}
                    </Typography>
                    <Typography
                      fontSize={'0.85rem'}
                      fontWeight={500}
                      color={!available ? Colors.lightGrey : ''}>
                      {' '}
                      min
                    </Typography>
                  </Box>
                  <Typography
                    textAlign={'left'}
                    fontFamily='"Nunito"'
                    fontSize={'1rem'}
                    fontWeight={500}
                    sx={{
                      color: available
                        ? selectedDuration === value
                          ? Colors.green.main
                          : '#576361'
                        : Colors.lightGrey
                    }}>
                    {description}
                  </Typography>
                </Box>
                {!available && (
                  <Box
                    sx={{
                      marginTop: isLargeScreen ? '-0.5rem' : '0.5rem',
                      background: Colors.shadowWhite,
                      padding: isLargeScreen ? '0.625rem' : '0.25rem',
                      borderRadius: '0.625rem',
                      minWidth: isLargeScreen ? '10rem' : '8rem',
                      marginRight: isLargeScreen ? '1.5rem' : ''
                    }}>
                    <Typography
                      fontFamily='"Exo 2"'
                      fontWeight={500}
                      lineHeight="1.25rem"
                      sx={{ color: Colors.secondary.main }}>
                      Coming soon
                    </Typography>
                  </Box>
                )}
              </Box>
            </Button>
          </Grid>
        ))}
      </Grid>
      <Grid container justifyContent="flex-end" columnSpacing={2} py={4}>
        <Grid item>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              navigate(RoutesEnum.USER_HOME);
              dispatch(setActualStep(1));
            }}>
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{ backgroundColor: Colors.bgGreen }}
            variant="contained"
            onClick={() => handleNextStep()}>
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AudioDuration;
