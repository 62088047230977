import { ReactComponent as BuildConfidence } from 'assets/images/home/ThePowerOfAffirmations/build-confidence.svg';
import { ReactComponent as ReduceStress } from 'assets/images/home/ThePowerOfAffirmations/reduce-stress.svg';
import { ReactComponent as StayMotivated } from 'assets/images/home/ThePowerOfAffirmations/stay-motivated.svg';
import useIsLargeScreen from 'hooks/useIsLargeScreen';

import { Box, Container, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { Colors, evo2FontFamily } from 'design/theme';

const valueFeats: {
  icon: React.FunctionComponent;
  title: string;
  text: string;
}[] = [
  {
    icon: BuildConfidence,
    title: 'Build Confidence',
    text: 'Replace negative thoughts with empowering statements to believe in yourself and your abilities.'
  },
  {
    icon: StayMotivated,
    title: 'Stay Motivated',
    text: 'Use affirmations to keep your goals top of mind and stay focused on what matters most.'
  },
  {
    icon: ReduceStress,
    title: 'Reduce Stress',
    text: 'Pair affirmations with visualization to calm your mind, improve your mood, and take action with clarity.'
  }
];

const ThePowerOfAffirmations = () => {
  const isLargeScreen = useIsLargeScreen();

  return (
    <Box
      sx={{
        width: '100%',
        height: isLargeScreen ? '45.8rem' : 'auto',
        marginTop: '10rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        ...(!isLargeScreen && {
          py: '3.5rem'
        })
      }}>
      <Container
        sx={{
          position: 'relative'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            zIndex: 20
          }}>
          <Typography
            sx={{
              color: Colors.neutralGrey,
              fontSize: '1rem',
              fontStyle: 'italic',
              fontWeight: 500,
              fontFamily: evo2FontFamily
            }}>
            Inspiring success stories
          </Typography>

          <Typography
            sx={{
              color: Colors.green.main,
              fontSize: '2.625rem',
              fontWeight: 700,
              fontFamily: evo2FontFamily,
              marginBottom: '0.25rem'
            }}>
            The Power of Affirmations
          </Typography>

          <Typography
            sx={{
              color: Colors.green.dark,
              fontSize: '1.5rem',
              fontWeight: 500,
              fontFamily: evo2FontFamily,
              width: '80%',
              maxWidth: '54rem',
              zIndex: 20
            }}>
            Affirmations are a simple and effective way to build a positive
            mindset, boost confidence, and stay focused on your goals. By
            practicing them daily, you can replace self-doubt with belief and
            create a clear path toward success.
          </Typography>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            top: '60%',
            left: '50%',
            width: '400px',
            height: '400px',
            transform: 'translate(-50%, -50%)',
            background: `radial-gradient(circle, ${alpha(
              Colors.green.light,
              0.6
            )} 0%, rgba(0, 0, 0, 0) 70%)`,
            zIndex: 0
          }}
        />

        <Box
          sx={{
            display: 'flex',
            gap: isLargeScreen ? '3rem' : '2rem',
            marginTop: isLargeScreen ? '5.375rem' : '3rem',
            flexDirection: isLargeScreen ? 'row' : 'column',
            backgroundColor: Colors.white,
            borderRadius: '0.625rem',
            paddingX: '3rem',
            paddingY: '4rem',
            position: 'relative',
            zIndex: 1
          }}>
          {valueFeats.map((feat) => (
            <Box
              key={feat.title}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                flexDirection: 'column'
              }}>
              <feat.icon />

              <Typography
                sx={{
                  color: Colors.green.main,
                  fontSize: '1.5rem',
                  fontWeight: 500,
                  fontFamily: evo2FontFamily,
                  textAlign: 'center'
                }}>
                {feat.title}
              </Typography>

              <Typography
                sx={{
                  color: Colors.green.dark,
                  fontSize: '1rem',
                  fontWeight: 500,
                  fontFamily: evo2FontFamily,
                  textAlign: 'center',
                  ...(!isLargeScreen && { width: '80%' })
                }}>
                {feat.text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default ThePowerOfAffirmations;
