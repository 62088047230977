import CustomizeYourContent from 'assets/images/home/HowDoesItWork/Slider/CustomizeYourContent.png';
import DefineAudio from 'assets/images/home/HowDoesItWork/Slider/DefineAudio.png';
import SelectYourTraits from 'assets/images/home/HowDoesItWork/Slider/SelectYourTraits.png';
import SetYourGoals from 'assets/images/home/HowDoesItWork/Slider/SetYoutGoals.png';
import useIsLargeScreen from 'hooks/useIsLargeScreen';

import { Box, Container, Typography } from '@mui/material';

import CarrouselHowWorks, { ContentType } from 'components/CarrouselHowWorks';

import { Colors, evo2FontFamily } from 'design/theme';

const content: ContentType[] = [
  {
    title: 'Start the Conversation:',
    description:
      'Begin by chatting with our friendly avatar, who’s here to get to know you. Together, you’ll explore the subject you want to focus on and clarify the outcomes you’re looking for.',
    image: SelectYourTraits
  },
  {
    title: 'Discover Your Personalized Path:',
    description:
      'Based on your chat, we’ll create a tailored suggestion, guiding you toward the right type of audio product—affirmations, visualizations, or meditations—designed to meet your needs.',
    image: SetYourGoals
  },
  {
    title: 'Make It Your Own:',
    description:
      'Refine the content by editing the text to suit your style. Then, select your preferred voice and background music to craft an experience that feels uniquely you.',
    image: CustomizeYourContent
  },
  {
    title: 'Enjoy Your Audio:',
    description:
      'Once everything is just right, your personalized audio product is ready for you to use anytime, helping you stay focused and inspired on your journey.',
    image: DefineAudio
  }
];

const HowDoesItWork = () => {
  const isLargeScreen = useIsLargeScreen();
  return (
    <Box position="relative">
      <Box
        zIndex={20}
        position="relative"
        sx={{ width: { xs: '100%', sm: '100%' }, margin: 'auto' }}>
        <Container>
          <Typography
            textAlign="center"
            fontFamily={evo2FontFamily}
            fontStyle="italic"
            fontWeight={500}
            color={Colors.secondary.main}
            fontSize={isLargeScreen ? '0.9rem' : '0.8rem'}>
            Get the most out of iAffirm
          </Typography>
          <Typography
            textAlign="center"
            fontFamily={evo2FontFamily}
            fontWeight={700}
            color={Colors.bgGreen}
            fontSize={isLargeScreen ? '2.625rem' : '1.7rem'}
            mb="4.875rem">
            How does it work?
          </Typography>
          <CarrouselHowWorks content={content} />
        </Container>
      </Box>
    </Box>
  );
};
export default HowDoesItWork;
