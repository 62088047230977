import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useEffect, useState } from 'react';

import { Box, Button, Grid } from '@mui/material';

import CardGridMeditation from 'components/CardGridMeditation';

import { Colors } from 'design/theme';

import { getQualitiesByOutcome } from 'redux/meditations/meditation.action';
import { medidationSelector } from 'redux/meditations/meditation.selector';
import {
  addOutcomeMeditation,
  cleanMeditationWantWorkOn,
  setActualStepMeditation,
  setAreaSelectedMeditation,
  setCustomFlowMeditation
} from 'redux/meditations/meditation.slice';
import { MeditationOutcome } from 'redux/meditations/types/meditation.entity';

import { analyticsEvents, logAnalyticsEvent } from '../../../utils/analytics';

export const areaEmojis = {
  Interpersonal: '🫂',
  Personal: '🙏',
  Professional: '💸'
};

type OutcomesMapping = {
  [area: string]: MeditationOutcome[];
};

const ImprovementAreaMeditation: React.FC = () => {
  const dispatch = useAppDispatch();
  const { actualStep, areaSelected } =
    useAppSelector(medidationSelector)?.newMeditation;
  const outcomes = useAppSelector(medidationSelector)?.meditationOutcomes;
  const [activeOutcomeId, setActiveOutcomeId] = useState<number | null>(null);
  const [customFlowSelected, setCustomFlowSelected] = useState<boolean>(false);
  const [selectedOutcome, setSelectedOutcome] = useState<any>(null);
  const areasDevelopment = ['Interpersonal', 'Personal', 'Professional'];

  useEffect(() => {
    logAnalyticsEvent(analyticsEvents.meditation.outcome_step);
  }, []);

  const handleOutcomeClick = async (
    outcome: MeditationOutcome,
    area: string
  ) => {
    setSelectedOutcome(outcome);
    if (outcome && outcome.id && outcome.id < 0) {
      setActiveOutcomeId(outcome.id);
      dispatch(setAreaSelectedMeditation({ area: area }));
      dispatch(cleanMeditationWantWorkOn());
      setCustomFlowSelected(true);
    } else if (outcome && outcome.id) {
      setActiveOutcomeId(outcome.id);
      dispatch(setAreaSelectedMeditation({ area: area }));
      dispatch(cleanMeditationWantWorkOn());
    } else {
      setActiveOutcomeId(null);
      dispatch(setCustomFlowMeditation(false));
      dispatch(setAreaSelectedMeditation({ area: area }));
      dispatch(cleanMeditationWantWorkOn());
    }
  };

  const handleNext = () => {
    dispatch(addOutcomeMeditation(selectedOutcome));
    if (activeOutcomeId) {
      dispatch(getQualitiesByOutcome(activeOutcomeId));
      dispatch(setActualStepMeditation(actualStep + 1));
    }
    if (customFlowSelected) {
      dispatch(setCustomFlowMeditation(true));
    }
  };
  const typedOutcomes = outcomes as unknown as OutcomesMapping;

  const handleScrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    dispatch(cleanMeditationWantWorkOn());
    dispatch(setAreaSelectedMeditation(undefined));
  }, [dispatch]);
  return (
    <>
      <Box>
        <CardGridMeditation
          areasDevelopment={areasDevelopment}
          typedOutcomes={typedOutcomes}
          handleOutcomeClick={handleOutcomeClick}
          activeOutcomeId={activeOutcomeId}
          handleScrollDown={handleScrollDown}
        />
      </Box>

      <Grid container justifyContent="flex-end" columnSpacing={2} py={4}>
        <Grid item>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              dispatch(setActualStepMeditation(1));
            }}>
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={!areaSelected}
            variant="contained"
            sx={{ backgroundColor: Colors.bgGreen }}
            onClick={handleNext}>
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ImprovementAreaMeditation;
