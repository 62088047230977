import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';

import CardGridVisualization from 'components/CardGridVisualization';

import { Colors } from 'design/theme';

import { setSelectedOutcome } from 'redux/affirmation/afirmation.slice';
import { Outcome } from 'redux/visualization/types/visualizations';
import { visualizationSelector } from 'redux/visualization/visualization.selector';
import {
  cleanVisualizationWantWorkOn,
  setActualStepVisualization,
  setAreaSelectedVisualization,
  setCustomFlowVisualization,
  setSelectedOutcomeVisualization
} from 'redux/visualization/visualization.slice';

import {
  analyticsEvents,
  logAnalyticsEvent
} from '../../../../../utils/analytics';

type OutcomesMapping = {
  [area: string]: Outcome[];
};

const ImprovementAreaVisualizations = () => {
  const dispatch = useAppDispatch();
  const { actualStep, areaSelected } = useAppSelector(
    visualizationSelector
  ).newVisualization;
  const { visualizationOutomes } = useAppSelector(visualizationSelector);
  const [activeOutcomeId, setActiveOutcomeId] = useState<number | null>(null);
  const [customFlowSelected, setCustomFlowSelected] = useState<boolean>(false);
  const areasDevelopment = ['Interpersonal', 'Personal', 'Professional'];

  const handleOutcomeClick = async (outcome: Outcome, area: string) => {
    if (outcome && outcome.id && outcome.id < 0) {
      setActiveOutcomeId(outcome.id);
      dispatch(setSelectedOutcomeVisualization(outcome));
      dispatch(setAreaSelectedVisualization({ area: area }));
      dispatch(cleanVisualizationWantWorkOn());
      setCustomFlowSelected(true);
    } else if (outcome && outcome.id) {
      setActiveOutcomeId(outcome.id);
      dispatch(setSelectedOutcomeVisualization(outcome));
      dispatch(setAreaSelectedVisualization({ area: area }));
      dispatch(cleanVisualizationWantWorkOn());
    } else {
      setActiveOutcomeId(null);
      dispatch(setCustomFlowVisualization(false));
      dispatch(setAreaSelectedVisualization({ area: area }));
      dispatch(setSelectedOutcomeVisualization(outcome));
      dispatch(cleanVisualizationWantWorkOn());
    }
  };

  const handleNext = () => {
    if (activeOutcomeId) {
      dispatch(setActualStepVisualization(actualStep + 1));
    }
    if (customFlowSelected) {
      dispatch(setCustomFlowVisualization(true));
    }
  };
  const typedOutcomes = visualizationOutomes as unknown as OutcomesMapping;

  const handleScrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    logAnalyticsEvent(analyticsEvents.visualization.improvement_step);
    dispatch(setSelectedOutcome(undefined));
    dispatch(cleanVisualizationWantWorkOn());
    dispatch(setAreaSelectedVisualization(undefined));
  }, []);

  return (
    <>
      <Grid container justifyContent="space-between" pb="1.5rem">
        <Grid item>
          <Typography color={Colors.black} fontSize="1.5rem" fontWeight={500}>
            In this area of improvement...
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontSize={'0.875rem'} fontWeight={500} color={'#576361'}>
            Select 1 area to set your positive outcome
          </Typography>
        </Grid>
      </Grid>

      <Box>
        <CardGridVisualization
          areasDevelopment={areasDevelopment}
          typedOutcomes={typedOutcomes}
          handleOutcomeClick={handleOutcomeClick}
          activeOutcomeId={activeOutcomeId}
          handleScrollDown={handleScrollDown}
        />
      </Box>

      <Grid container justifyContent="flex-end" columnSpacing={2} py={4}>
        <Grid item>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              dispatch(setActualStepVisualization(1));
            }}>
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={!areaSelected}
            variant="contained"
            sx={{ backgroundColor: Colors.bgGreen }}
            onClick={handleNext}>
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ImprovementAreaVisualizations;
